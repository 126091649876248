import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';

import ActionButton from '../buttons/ActionButton';
import LinkButton from '../LinkButton';
import LabelledInput from '../input/LabelledInput';
import * as Forms from './Form';
import { ROUTES } from '../../../constants/routes';
import { CatchFirebaseFormError } from '../../../constants/errorMessages';
import { State } from '../../../redux/reducer';
import { removeLoginRedirectionUrl } from '../../../redux/reducers/LoginRedirectionReducer';

interface FormData {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const { handleSubmit, register, errors, setError, clearError } = useForm<
    FormData
  >();

  const redirectionURL = useSelector((state: State) =>
    state.loginRedirection ? state.loginRedirection : '/'
  );

  const onSubmit = ({ email, password }: FormData) => {
    clearError();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        dispatch(removeLoginRedirectionUrl());
        dispatch(push(redirectionURL));
      })
      .catch(CatchFirebaseFormError(setError));
  };

  return (
    <Forms.Container onSubmit={handleSubmit(onSubmit)}>
      <Forms.Content>
        <Forms.Title>Bienvenue</Forms.Title>
        <Forms.Subtitle>
          {`Connecte toi pour retrouver d'autres teewistes !`}
        </Forms.Subtitle>
        <Forms.Errors errors={Object.values(errors)} />
        <LabelledInput
          innerRef={register}
          label="Email"
          name="email"
          type="email"
          required
        />
        <LabelledInput
          innerRef={register}
          label="Mot de passe"
          type="password"
          name="password"
          required
        />
      </Forms.Content>
      <Forms.Actions>
        <ActionButton text="Se connecter" variant="primary" type="submit" />
        <LinkButton
          text="Mot de passe oublié"
          onClick={() => {
            dispatch(push(ROUTES.RESET));
          }}
        />
        <div style={{ height: '2em' }} />
        <ActionButton
          text="S'inscrire"
          variant="secondary"
          onClick={() => dispatch(push(ROUTES.REGISTER))}
        />
      </Forms.Actions>
    </Forms.Container>
  );
};

export default LoginForm;
