import {
  ExtendedFirestoreInstance,
  ExtendedStorageInstance,
} from 'react-redux-firebase';
import { COLLECTIONS } from '../constants/collections';
import firebase, { User } from 'firebase';
import { StatusModel } from '../models/StatusModel';
import { StatusColor } from '../constants/colorTypes';
import { TeeWeeUserModel, EmptyTeeWeeUser } from '../models/TeeWeeUserModel';
import { SocialNetworks } from '../models/SocialNetworksModel';
import { compressImage } from '../utils/CompressUtils';
import { uploadImageFromString } from '../utils/CropUtils';

export const consolidateTeeWeeUser = (user: TeeWeeUserModel) => {
  return Object.assign({}, EmptyTeeWeeUser, user);
};

export const registerTeeWeeUser = (
  uid: string,
  username: string,
  firestore: ExtendedFirestoreInstance,
  scannedUid?: string
) => {
  //Update
  const teeweeUser = Object.assign(
    { username },
    scannedUid ? { scanned: [scannedUid] } : {}
  );
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .set(teeweeUser, { merge: true });
};

export const updateUsername = (
  username: string,
  uid: string,
  firestore: ExtendedFirestoreInstance
) => {
  //Update
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .set({ username }, { merge: true });
};

export const createFirstScanned = (
  scannedUid: string,
  uid: string,
  firestore: ExtendedFirestoreInstance
) => {
  //Update
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .set({ scanned: [scannedUid] }, { merge: true });
};

export const addNewScanned = (
  scannedUid: string,
  uid: string,
  firestore: ExtendedFirestoreInstance
) => {
  //Update
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .update({ scanned: firebase.firestore.FieldValue.arrayUnion(scannedUid) });
};

export const updateTeeweeUserModel = (
  user: TeeWeeUserModel,
  firestore: ExtendedFirestoreInstance
) => {
  //Create model without undefined
  const newModel: {
    username?: string;
    photoURL?: string;
    allowAnonymousComments?: boolean;
    socialNetworks?: SocialNetworks;
  } = {};
  if (user.username) {
    newModel.username = user.username;
  }
  if (user.photoURL) {
    newModel.photoURL = user.photoURL;
  }
  if (user.allowAnonymousComments !== undefined) {
    newModel.allowAnonymousComments = user.allowAnonymousComments;
  }
  if (user.socialNetworks !== undefined) {
    newModel.socialNetworks = user.socialNetworks;
  }

  //Update
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(user.uid)
    .update(newModel);
};

export const sendNewComment = (
  content: string,
  targetUid: string,
  targetStatus: string,
  firestore: ExtendedFirestoreInstance,
  fromUid?: string
) => {
  const newComment = Object.assign(
    {
      content: content,
      targetStatus: targetStatus,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
    fromUid ? { fromUid } : {}
  );

  firestore
    .collection(COLLECTIONS.USERS)
    .doc(targetUid)
    .collection(COLLECTIONS.COMMENTS)
    .add(newComment);
};

export const deleteComment = (
  targetUid: string,
  commentId: string,
  firestore: ExtendedFirestoreInstance
) => {
  firestore
    .collection(COLLECTIONS.USERS)
    .doc(targetUid)
    .collection(COLLECTIONS.COMMENTS)
    .doc(commentId)
    .delete();
};

export const sendNewAnswer = (
  answer: string,
  targetUid: string,
  commentId: string,
  firestore: ExtendedFirestoreInstance
) => {
  firestore
    .collection(COLLECTIONS.USERS)
    .doc(targetUid)
    .collection(COLLECTIONS.COMMENTS)
    .doc(commentId)
    .update({
      answer: answer,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const deleteAnswerFromComment = (
  targetUid: string,
  commentId: string,
  firestore: ExtendedFirestoreInstance
) => {
  firestore
    .collection(COLLECTIONS.USERS)
    .doc(targetUid)
    .collection(COLLECTIONS.COMMENTS)
    .doc(commentId)
    .update({
      answer: firebase.firestore.FieldValue.delete(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const sendNewStatus = (
  user: User,
  firestore: ExtendedFirestoreInstance,
  color: StatusColor,
  content?: string,
  imagePath?: string
) => {
  firestore
    .collection(COLLECTIONS.USERS)
    .doc(user.uid)
    .collection(COLLECTIONS.STATUS)
    .add(
      Object.assign(
        {
          answeredBy: [],
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        content ? { content: content, color: color } : { imagePath: imagePath }
      )
    )
    .catch(e => console.error('Error upload status', e));
};

export const deleteStatus = (
  userId: string,
  status: StatusModel,
  firestore: ExtendedFirestoreInstance
) => {
  if (status.answeredBy.length === 0) {
    // If there was no answer, we delete
    firestore
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .collection(COLLECTIONS.STATUS)
      .doc(status.id)
      .delete();
  } else {
    // If not, we only mark the status as removed
    firestore
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .collection(COLLECTIONS.STATUS)
      .doc(status.id)
      .update({
        imagePath: firebase.firestore.FieldValue.delete(),
        content: firebase.firestore.FieldValue.delete(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }
};

export const uploadProfilePicture = async (
  imageUrl: string,
  currentUser: User | null,
  storage: ExtendedStorageInstance,
  firestore: ExtendedFirestoreInstance
) => {
  if (!currentUser) {
    return;
  }
  const storageRef = storage.storage().ref();

  const pictureRef = storageRef.child(
    '/users/' + currentUser.uid + '/profile/me'
  );

  // Upload cropped blob
  const resizedCroppedImageStr = await compressImage(imageUrl, 510 * 1024);

  await uploadImageFromString(pictureRef, resizedCroppedImageStr);

  const photoURL = await pictureRef.getDownloadURL();

  console.log('Photo URL: ', photoURL);
  await firestore
    .collection(COLLECTIONS.USERS)
    .doc(currentUser.uid)
    .update({ photoURL: photoURL });
};
