import React from 'react';
import { LegalSection } from './LegalPage';
export function MentionsLegales() {
  const m1 = 'teewee.fr';
  const m2 = ['con', 'tact@'];
  m2.push(m1);
  const mail = m2.join('');

  return (
    <LegalSection title="Mentions Légales">
      <>
        <p>
          Le site internet TeeWee (teewee.fr) est édité par La Compagnie Des
          ndaires, SAS au capital social de 1000€, dont le siège social est
          situé au 4 rue des Saules (31400, Toulouse).
        </p>
        <p>
          Vous pouvez nous contacter au mail suivant: <a href={mail}>{mail}</a>
        </p>
        <p>Numéro SIRET/SIREN : 87789928600018/877899286</p>
        <p>Numéro de TVA intracommunautaire : FR4287789928600018</p>
        <p>
          Le site internet teewee.fr est hébergé par Firebase, 188 King ST, San
          Francisco, CA 94107, United States, https://firebase.google.com/.
        </p>
      </>
    </LegalSection>
  );
}
