import React, { useState } from 'react';
import { SettingsSection } from './MenuComponents';
import * as Forms from '../../components/forms/Form';
import LabelledInput from '../../components/input/LabelledInput';
import ActionButton from '../../components/buttons/ActionButton';
import { useForm } from 'react-hook-form';
import { H_MARGIN } from '../../../constants/style';
import styled from 'styled-components';
import CheckBoxInput from '../../components/input/CheckBoxInput';
import { useCurrentUser } from '../../../utils/AuthUtils';
import FormSection from '../../components/input/FormSection';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { updateTeeweeUserModel } from '../../../controllers/UserController';
import { CatchFirebaseFormError } from '../../../constants/errorMessages';

const FormWithSpace = styled.div`
  label {
    margin-bottom: ${H_MARGIN};
  }
  margin-bottom: ${H_MARGIN};
`;

interface FormData {
  username?: string;
  email?: string;
  oldPassword?: string;
  newPassword?: string;
  allowAnonymousComments: boolean;
}

export const AccountSettingsSection = () => {
  const {
    handleSubmit,
    register: formRef,
    errors,
    clearError,
    setError,
  } = useForm<FormData>();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const currentFirebaseUser = firebase.auth().currentUser;
  const [infos, setInfos] = useState<string[]>([]);

  const addInfo = (info: string) => {
    setInfos(value => value.concat(info));
  };

  const currentUser = useCurrentUser();

  const onSubmit = (data: FormData) => {
    if (!currentUser || !currentFirebaseUser) {
      return;
    }
    clearError();
    setInfos([]);

    // Firebase User
    const updateEmail = data.email && currentFirebaseUser.email !== data.email;
    const updatePassword =
      data.newPassword &&
      data.oldPassword &&
      data.oldPassword !== data.newPassword;

    if (updateEmail || updatePassword) {
      // @ts-ignore
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentFirebaseUser.email,
        data.oldPassword
      );

      currentFirebaseUser
        .reauthenticateWithCredential(credential)
        .then(function() {
          if (data.email && updateEmail) {
            currentFirebaseUser
              .updateEmail(data.email)
              .then(() => addInfo('Le mail a été changé'))
              .catch(CatchFirebaseFormError(setError));
          }
          if (data.newPassword && updatePassword) {
            currentFirebaseUser
              .updatePassword(data.newPassword)
              .then(() => addInfo('Le mot de passe a été modifié'))
              .catch(CatchFirebaseFormError(setError));
          }
        })
        .catch(CatchFirebaseFormError(setError));
    }

    // TeeWeeModel
    let updateUsername = false;
    let updateAllowAnonymousComments = false;
    const newUser = Object.assign({}, currentUser);
    if (data.username && data.username !== currentUser.username) {
      //Switch username
      newUser.username = data.username;
      updateUsername = true;
    }
    if (data.allowAnonymousComments !== currentUser.allowAnonymousComments) {
      newUser.allowAnonymousComments = data.allowAnonymousComments;
      updateAllowAnonymousComments = true;
    }

    if (updateUsername || updateAllowAnonymousComments) {
      updateTeeweeUserModel(newUser, firestore)
        .then(() => {
          if (updateUsername) {
            addInfo("Nouveau nom d'utilisateur");
          }
          if (updateAllowAnonymousComments) {
            addInfo('Changement de la politique commentaires ');
          }
        })
        .catch(CatchFirebaseFormError(setError));
    }
  };

  return (
    <SettingsSection title="Réglages du compte">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWithSpace>
          <Forms.Errors errors={Object.values(errors)} />
          <Forms.Infos infos={infos} />
          <LabelledInput
            innerRef={formRef}
            label="Nom d'utilisateur"
            name="username"
            type="text"
            defaultValue={currentUser?.username}
          />
          <LabelledInput
            innerRef={formRef}
            label="Email"
            name="email"
            type="email"
          />
          <LabelledInput
            innerRef={formRef}
            label="Mot de passe actuel"
            type="password"
            name="oldPassword"
          />
          <LabelledInput
            innerRef={formRef}
            label="Nouveau mot de passe"
            type="password"
            name="newPassword"
          />
          <FormSection>Confidentialité</FormSection>
          <CheckBoxInput
            innerRef={formRef}
            text={'Autoriser les commentaires anonymes'}
            name="allowAnonymousComments"
            defaultChecked={currentUser?.allowAnonymousComments}
          />
        </FormWithSpace>
        <Forms.Actions>
          <ActionButton
            text="Enregistrer les modifications"
            variant="primary"
            type="submit"
          />
        </Forms.Actions>
      </form>
    </SettingsSection>
  );
};
