import React from 'react';
import { SettingsSection } from './MenuComponents';
import styled from 'styled-components';
import {
  CardStyle,
  ShadowedCard,
  ShadowedCardButton,
} from '../../components/cards/DefaultCard';
import { useCurrentUser } from '../../../utils/AuthUtils';
import { ShirtBig } from '../../../assets/images/imagesPNG';
import {
  BUTTON_SECONDARY_COLOR_TEXT,
  CARDS_TEXT_COLOR,
  H_MARGIN,
  TITLE_TEXT_COLOR,
} from '../../../constants/style';
import { unlinkShirt } from '../../../controllers/ShirtsController';
import { useFirestore } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../../../constants/routes';
import { push } from 'connected-react-router';

const ShirtCardRightStyle = styled.div`
  text-align: left;
  h1 {
    font-weight: 800;
    color: ${TITLE_TEXT_COLOR};
    margin-bottom: 0.5em;
  }

  p {
    font-weight: 500;
    color: #a6a6a6;

    &:hover {
      color: red;
      cursor: pointer;
    }

    &:active {
      color: #800;
      user-select: none;
    }
  }

  padding-bottom: 0.5em;
`;

const ShirtCardStyle = styled(CardStyle)`
  display: flex;
  direction: row;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

const NoShirtWarningStyle = styled.div`
  margin-bottom: 3em;
  text-align: center;

  h1 {
    color: ${TITLE_TEXT_COLOR};
    font-size: 150%;
    margin-bottom: 1em;
  }

  p {
    color: ${CARDS_TEXT_COLOR};
    font-decoration: italic;
  }
`;

const StoreButton = styled(ShadowedCardButton)`
  margin-top: 1rem;
  color: ${BUTTON_SECONDARY_COLOR_TEXT};

  &:hover {
    box-shadow: inset 0 4px 66px 0 rgba(0, 0, 0, 0.1);
  }

  &:active {
    filter: brightness(85%);
  }
`;

const ShirtCard: React.FC<{ code: string }> = ({ code }) => {
  const firestore = useFirestore();

  return (
    <ShirtCardStyle>
      <img
        src={ShirtBig}
        alt="Teeshirt teewee"
        style={{ maxWidth: '25%', marginRight: H_MARGIN }}
      />
      <ShirtCardRightStyle>
        <h1>{code}</h1>
        <p onClick={() => unlinkShirt(code, firestore)}>Supprimer</p>
      </ShirtCardRightStyle>
    </ShirtCardStyle>
  );
};

export const VestiaireSettingsSection = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();

  if (!user || !user.shirts) {
    return <p>Loading</p>;
  }

  return (
    <SettingsSection title="Mon vestiaire">
      {user.shirts.length === 0 ? (
        <>
          <NoShirtWarningStyle>
            <h1>Ton vestiaire est vide :'(</h1>
            <p>TeeWee est bien plus marrant quand il est rempli</p>
          </NoShirtWarningStyle>
          <ShadowedCard>Scanne un teeshirt pour l'ajouter</ShadowedCard>
          <h1
            style={{ textAlign: 'center', marginTop: '1rem', fontSize: '150%' }}
          >
            OU
          </h1>
        </>
      ) : (
        <ul>
          {user.shirts.map(shirtCode => {
            return <ShirtCard key={shirtCode} code={shirtCode} />;
          })}
        </ul>
      )}
      <StoreButton onClick={() => dispatch(push(ROUTES.STORE))}>
        {user.shirts.length === 0
          ? 'Achète ton teeshirt'
          : 'Accéder à la boutique'}
      </StoreButton>
    </SettingsSection>
  );
};
