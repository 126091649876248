import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  PictoCross,
  PictoSend,
  PictoMountain,
  RedShape,
  YellowShape,
  BlueShape,
  QuoteOpen,
  QuoteClose,
} from '../../../assets/images/imagesSVG';
import { ButtonCard, ButtonIcon } from '../buttons/ButtonCard';
import { Quote, StatusStyleContainer, StatusStyleContent } from './StatusCard';
import { StatusColor } from '../../../constants/colorTypes';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { sendNewStatus } from '../../../controllers/UserController';
import { CropPopUp } from '../modal/CropPopUp';
import { ImageInput } from '../misc/ImageInput';
import { uploadImageStatus } from '../../../utils/UploadImageStatus';

export const StatusButtonCard = styled(ButtonCard)`
  position: absolute;
  width: 2rem;
  height: 2rem;
  margin: 0;
`;

const ColumnButton = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;

  ${StatusButtonCard} {
    position: static;
    margin-bottom: 0.8rem;
  }
`;

const RightColumnButton = styled(ColumnButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const LeftColorButton = styled(ColumnButton)``;

const LeftButtons = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: 1rem;
  left: 1rem;

  ${StatusButtonCard} {
    position: static;
    margin-right: 0.8rem;
    border: 0.2rem solid #aaaaaaaa;
  }
`;

const TextStatusInput = styled.textarea`
  width: 100%;
  border: none;
  background: #fff5;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: 'HKNova';

  color: white;

  text-shadow: 0 2px 34px rgba(123, 21, 0, 0.46);
  font-size: 1.8rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
`;

const MountainButton = styled(StatusButtonCard)`
  background: url(${PictoMountain});
  background-size: 2rem 2rem;
  background-position: center;

  padding: 0;

  label {
    display: inline-block;
    width: 100%;
    height: 100%;

    input {
      display: none;
    }
  }
`;

const ImagePreview = styled.img`
  max-width: 100%;
  min-width: 100%;
  width: 100%;
`;

const NewStatusButtons: React.FC<{
  color: StatusColor;
  onSwitchColor: (newColor: StatusColor) => void;
  onClose: () => void;
  onSend: () => void;
  isText: boolean;
  onSwitchToText: () => void;
  onSwitchToImage: (imageUrl: string | null) => void;
}> = ({
  color,
  onSwitchColor,
  onClose,
  onSend,
  isText,
  onSwitchToText,
  onSwitchToImage,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const colorDict = { yellow: '#f6b033', red: '#f13c2a', blue: '#2f45c1' };

  const ColorButton: React.FC<{
    color: StatusColor;
  }> = ({ color }) => (
    <StatusButtonCard
      onClick={() => {
        onSwitchColor(color);
        setDisplayColorPicker(false);
      }}
      style={{ background: colorDict[color] }}
    />
  );

  return (
    <>
      <LeftButtons>
        {isText ? (
          displayColorPicker ? (
            <LeftColorButton>
              <ColorButton color="red" />
              <ColorButton color="blue" />
              <ColorButton color="yellow" />
            </LeftColorButton>
          ) : (
            <StatusButtonCard
              onClick={() => setDisplayColorPicker(true)}
              style={{
                background: colorDict[color],
              }}
            ></StatusButtonCard>
          )
        ) : (
          <StatusButtonCard onClick={onSwitchToText}>
            <p>Aa</p>
          </StatusButtonCard>
        )}

        <MountainButton>
          <label>
            <ImageInput onSelect={onSwitchToImage} />
          </label>
        </MountainButton>
      </LeftButtons>

      <RightColumnButton>
        <StatusButtonCard onClick={onClose}>
          <ButtonIcon src={PictoCross} />
        </StatusButtonCard>
        <StatusButtonCard onClick={onSend}>
          <ButtonIcon src={PictoSend} />
        </StatusButtonCard>
      </RightColumnButton>
    </>
  );
};

export const NewStatusCard: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const firestore = useFirestore();
  const firebase = useFirebase();
  const loggedUser = firebase.auth().currentUser;

  // Text status
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [currentColor, setCurrentColor] = useState<StatusColor>('red');
  const [textStatus, setTextStatus] = useState('');

  // ImageStatus
  const [fullImageUrl, setFullImageUrl] = useState('');
  const [displayCropPopup, setDisplayCropPopup] = useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);

  const isText = croppedImageUrl === null;

  const backgroundDict = {
    yellow: YellowShape,
    red: RedShape,
    blue: BlueShape,
  };

  const uploadImageStatusShort = () =>
    uploadImageStatus(
      loggedUser,
      firebase,
      firestore,
      fullImageUrl,
      croppedImageUrl ? croppedImageUrl : '',
      onClose
    );

  return (
    <>
      {displayCropPopup && (
        <CropPopUp
          imageUrl={fullImageUrl}
          onExit={() => {
            setFullImageUrl('');
            setCroppedImageUrl(null);
            setDisplayCropPopup(false);
          }}
          onComplete={croppedImageUrl => {
            setCroppedImageUrl(croppedImageUrl);
            setDisplayCropPopup(false);
          }}
        />
      )}

      <NewStatusButtons
        color={currentColor}
        isText={isText}
        onSwitchToText={() => setCroppedImageUrl(null)}
        onSwitchToImage={imageUrl => {
          if (imageUrl === null) {
            setFullImageUrl('');
          } else {
            console.log(fullImageUrl);
            setFullImageUrl(imageUrl);
            setDisplayCropPopup(true);
          }
        }}
        onSwitchColor={setCurrentColor}
        onSend={() => {
          if (isText) {
            if (loggedUser) {
              sendNewStatus(loggedUser, firestore, currentColor, textStatus);
            }
            onClose();
          } else {
            uploadImageStatusShort();
          }
        }}
        onClose={onClose}
      />

      <StatusStyleContainer>
        {croppedImageUrl === null ? (
          <>
            <img src={backgroundDict[currentColor]} alt={currentColor} />
            <StatusStyleContent>
              <Quote src={QuoteOpen} alt={'Opening quote'} />
              <TextStatusInput
                ref={textInputRef}
                rows={1}
                maxLength={110}
                style={{
                  height:
                    textInputRef && textInputRef.current
                      ? textInputRef.current.scrollHeight
                      : 'auto',
                  resize: 'none',
                  fontSize: !(textInputRef && textInputRef.current)
                    ? 'auto'
                    : textInputRef.current.clientWidth / 20 + 'px',

                  lineHeight: !(textInputRef && textInputRef.current)
                    ? 'auto'
                    : Math.max(
                        textInputRef.current.clientHeight / 4,
                        textInputRef.current.clientWidth / 20
                      ) + 'px',
                }}
                onChange={e => {
                  setTextStatus(e.target.value);
                }}
              />
              <Quote src={QuoteClose} alt={'Closing quote'} />
            </StatusStyleContent>
          </>
        ) : (
          <ImagePreview
            src={croppedImageUrl}
            alt="Prochain statut"
            onClick={() => setDisplayCropPopup(true)}
          />
        )}
      </StatusStyleContainer>
    </>
  );
};
