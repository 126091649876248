import React from 'react';
import styled from 'styled-components';
import { PictoSend } from '../../../assets/images/imagesSVG';
import { H_MARGIN, MAX_WIDTH } from '../../../constants/style';

// Send message
export const InputMargin = styled.div`
  width: 100%;
  height: 6rem;
  position: relative;
  flex: none;
`;
export const FormContainer = styled.form`
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: ${MAX_WIDTH};
  z-index: 10;

  border-top: 2px solid #0000000f;
  padding: 0.5rem ${H_MARGIN};
  height: 4rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;

  input {
    flex-grow: 1;
    margin-right: 0.5rem;
    font-size: 0.9rem;

    border-radius: 17px;
    box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e1e1e1;
    font-family: 'HKNova';
    background-color: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;

    &:focus,
    &:active {
      outline: none;
    }

    &[type='submit'] {
      display: none;
    }
  }

  button {
    text-decoration: none;
    border: none;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);

    opacity: 1;

    &:hover {
      opacity: 0.75;
    }

    &:focus,
    &:active {
      opacity: 0.5;
      outline: none;
    }
  }
`;

// eslint-disable-next-line react/display-name
export const SendMessageForm = React.forwardRef<
  HTMLInputElement,
  { onSubmit: () => void }
>(({ onSubmit }, ref) => {
  return (
    <InputMargin>
      <FormContainer
        onSubmit={event => {
          event.preventDefault();
          onSubmit();
        }}
        action="#"
      >
        <input type={'text'} ref={ref}></input>
        <button type="submit">
          <img src={PictoSend} alt="Envoyer" />
        </button>
        <input type="submit" />
      </FormContainer>
    </InputMargin>
  );
});
