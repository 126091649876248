import React, { useRef } from 'react';

export const ImageInput: React.FC<{
  onSelect: (imageUrl: string | null) => void;
  alt?: string;
}> = ({ onSelect, alt }) => {
  const uploadPhotoRef = useRef<HTMLInputElement>(null);

  const selectPicture = async () => {
    if (uploadPhotoRef.current?.files) {
      const reader = new FileReader();
      const file = uploadPhotoRef.current?.files[0];
      reader.readAsDataURL(file);
      const imageDataUrl = URL.createObjectURL(file);

      reader.onloadend = async () => {
        if (!reader.result || reader.result instanceof ArrayBuffer) {
          onSelect(null);
        } else {
          onSelect(imageDataUrl);
        }
      };
    } else {
      onSelect(null);
    }
  };

  return (
    <input
      type="file"
      ref={uploadPhotoRef}
      id="upload-photo"
      accept="image/*"
      onChange={selectPicture}
      alt={alt}
    />
  );
};
