import React, { Dispatch, useRef, useState } from 'react';
import { TeeWeeUserModel } from '../../../models/TeeWeeUserModel';
import {
  FlexStructure,
  PageContainer,
} from '../../components/structure/DefaultBackground';
import {
  HeaderBar,
  HeaderButtonInfos,
} from '../../components/structure/HeaderBar';
import styled from 'styled-components';
import { StatusEntries } from '../../../redux/FirestoreData';
import { StatusModel } from '../../../models/StatusModel';

import { H_MARGIN } from '../../../constants/style';
import { UserCard } from '../../components/cards/UserCard';
import { InputCard } from '../../components/cards/InputCard';
import { sendNewComment } from '../../../controllers/UserController';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { CommentModel } from '../../../models/CommentModel';
import { FirestoreDateToDate } from '../../../models/FirestoreFields';
import { CurrentStatusCard } from '../../components/cards/CurrentStatusCard';
import {
  MiniPictoUser,
  PictoChat,
  PictoGear,
} from '../../../assets/images/imagesSVG';
import { ROUTES } from '../../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { TimelineSection } from './TimelineSection';
import { LoginOnAnonymousCommentModal } from '../../components/modal/LoginOnAnonymousCommentModal';
import {
  addAnonymousCommentContent,
  removeAnonymousCommentContent,
} from '../../../redux/reducers/AnonymousCommentReducer';
import { State } from '../../../redux/reducer';
import { BottomTabBar } from '../../components/structure/BottomTabBar';
import { useCurrentUser } from '../../../utils/AuthUtils';
import {
  ShadowedCard,
  ShadowedCardButton,
} from '../../components/cards/DefaultCard';
import { createChatId } from '../../../utils/ChatsUtils';

// Images

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MainContainer = styled(PageContainer as any)`
  padding: 0vh 0vw;
`;

const MarginBody = styled.div`
  padding: 0 ${H_MARGIN};
`;

//////

function buildHeaderAction(
  loggedUser: firebase.User | null,
  dispatch: Dispatch<unknown>,
  isMyPage: boolean,
  loggedUserTeewee: TeeWeeUserModel | undefined,
  user: TeeWeeUserModel
) {
  if (!loggedUser || !loggedUserTeewee) {
    // Login
    return {
      src: MiniPictoUser,
      onClick: () => dispatch(push(ROUTES.LOGIN)),
    };
  }

  const chatId = createChatId(loggedUserTeewee.uid, user.uid);

  if (isMyPage) {
    // Parameters
    return {
      src: PictoGear,
      onClick: () => dispatch(push(ROUTES.SETTINGS_MAIN)),
    };
  } else if (
    (loggedUserTeewee.canTalkTo &&
      loggedUserTeewee.canTalkTo.includes(user.uid)) ||
    (loggedUserTeewee.chatsRooms &&
      loggedUserTeewee.chatsRooms.includes(chatId))
  ) {
    // Chat
    return {
      src: PictoChat,
      onClick: () => dispatch(push(ROUTES.CHATS + chatId)),
    };
  }
}

const UserPage: React.FC<{
  user: TeeWeeUserModel;
  status: StatusEntries;
  comments: { [commentId: string]: CommentModel };
  canAnswer: boolean;
}> = ({ user, status, comments, canAnswer }) => {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const loggedUser = firebase.auth().currentUser;
  const loggedUserTeewee = useCurrentUser();

  const newCommentRef = useRef<HTMLTextAreaElement>(null);

  const [recentlyAnswer, setRecentlyAnswer] = useState(true);
  const [showLoginOnComment, setShowLoginOnComment] = useState(false);

  const lastStatus: string | undefined = Object.keys(status).sort((a, b) => {
    return status[b]
      ? status[a]
        ? FirestoreDateToDate(status[b].createdAt).getTime() -
          FirestoreDateToDate(status[a].createdAt).getTime()
        : 1
      : -1;
  })[0];
  const currentStatus: StatusModel | undefined = lastStatus
    ? status[lastStatus]
    : undefined;

  const isMyPage = loggedUser?.uid === user.uid;

  const sendComment = (content: string) => {
    sendNewComment(
      content,
      user.uid,
      user.currentStatus,
      firestore,
      loggedUser ? loggedUser.uid : undefined
    );
    setRecentlyAnswer(false);
    if (showLoginOnComment) {
      setShowLoginOnComment(false);
    }
  };

  // If Anonymous content is filled and we are logged, we have to send the message
  const anonymousCommentContent = useSelector(
    (state: State) => state.anonymousComment
  );
  if (loggedUser && anonymousCommentContent) {
    sendComment(anonymousCommentContent);
    dispatch(removeAnonymousCommentContent());
  }

  let linkSentence = '';
  if (
    loggedUserTeewee &&
    loggedUserTeewee.scanned &&
    loggedUserTeewee.scannedBy
  ) {
    if (loggedUserTeewee?.uid === user.uid) {
      linkSentence = 'Je suis';
    } else if (
      loggedUserTeewee.scanned.includes(user.uid) &&
      loggedUserTeewee.scannedBy.includes(user.uid)
    ) {
      linkSentence = 'Flash réciproque';
    } else if (loggedUserTeewee.scanned.includes(user.uid)) {
      linkSentence = "J'ai flashé";
    } else if (loggedUserTeewee.scannedBy.includes(user.uid)) {
      linkSentence = 'Flashé par';
    } else {
      linkSentence = 'Profil de';
    }
  }

  const headerButton: HeaderButtonInfos | undefined = buildHeaderAction(
    loggedUser,
    dispatch,
    isMyPage,
    loggedUserTeewee,
    user
  );

  return (
    <FlexStructure style={{ backgroundColor: '#fff' }}>
      <HeaderBar showBackButton={true} button={headerButton} />
      <MainContainer>
        {currentStatus ? (
          <CurrentStatusCard status={currentStatus} isMyPage={isMyPage} />
        ) : null}

        <div style={{ paddingBottom: '0rem' }}>
          <MarginBody>
            <UserCard user={user} linkSentence={linkSentence} />
          </MarginBody>

          {isMyPage && (!user.shirts || user.shirts.length === 0) ? (
            <MarginBody>
              <ShadowedCardButton onClick={() => dispatch(push(ROUTES.STORE))}>
                Il te faut un tee-shirt pour recevoir des messages :/
              </ShadowedCardButton>
            </MarginBody>
          ) : null}

          {canAnswer && recentlyAnswer ? (
            <MarginBody>
              {user.shirts && user.shirts.length > 0 ? (
                <InputCard
                  placeholder={`Laissez un message à ${user.username}...`}
                  style={{ marginTop: '-1.5em' }}
                  ref={newCommentRef}
                  onSubmit={(event, content) => {
                    if (!loggedUser) {
                      if (newCommentRef.current) {
                        dispatch(
                          addAnonymousCommentContent(
                            newCommentRef.current.value
                          )
                        );
                      }
                      setShowLoginOnComment(true);
                    } else {
                      sendComment(content);
                    }
                    event.preventDefault();
                  }}
                />
              ) : (
                <ShadowedCard>
                  {user.username} n&apos;a pas encore de teeshirts
                </ShadowedCard>
              )}
            </MarginBody>
          ) : null}

          <TimelineSection
            isMyPage={isMyPage}
            status={status}
            comments={comments}
            currentStatusId={lastStatus}
            pageOwner={user}
          />

          {newCommentRef.current ? (
            <LoginOnAnonymousCommentModal
              visible={showLoginOnComment}
              disappearFunction={() => setShowLoginOnComment(false)}
              targetUser={user}
              commentContent={newCommentRef.current.value}
              sendAnonymousComment={() =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                sendComment(newCommentRef.current!.value)
              }
            />
          ) : null}
        </div>
        {loggedUserTeewee ? (
          <BottomTabBar currentUser={loggedUserTeewee} />
        ) : null}
      </MainContainer>
    </FlexStructure>
  );
};

export default UserPage;
