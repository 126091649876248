import React from 'react';
import { LegalSection } from './LegalPage';
export function PolitiqueCookies() {
  return (
    <LegalSection title="Utilisation des cookies">
      <p>
        TeeWee n&apos;utilise pour son fonctionnement que les cookies
        strictement nécessaires à l&apos;authenfication et à
        l&apos;identification de l&apos;utilisateur
      </p>
      <p>
        Aucun cookie publicitaire, de traçage, ou d&apos;analyse ne sont
        utilisés par le présent site.
      </p>
    </LegalSection>
  );
}
