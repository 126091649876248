import React from 'react';
import styled from 'styled-components';
import ColoredShapes from '../../../assets/images/background_shapes.svg';
import { BaseStructure } from './DefaultBackground';

const StyledBackground = styled(BaseStructure)`
  background: url(${ColoredShapes});
  background-size: contain;
`;

export const ShapedBackground: React.FC = ({ children }) => {
  return <StyledBackground>{children}</StyledBackground>;
};

export default ShapedBackground;
