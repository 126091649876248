// AnonymousComment
const ADD_ANONYMOUS_COMMENT_CONTENT = '@@teewee/ADD_ANONYMOUS_COMMENT_CONTENT';
const REMOVE_ANONYMOUS_COMMENT_CONTENT =
  '@@teewee/REMOVE_ANONYMOUS_COMMENT_CONTENT';

interface AnonymousCommentAction {
  type: string;
  anonymousCommentContent?: string;
}

export function addAnonymousCommentContent(
  anonymousCommentContent: string
): AnonymousCommentAction {
  return {
    type: ADD_ANONYMOUS_COMMENT_CONTENT,
    anonymousCommentContent,
  };
}

export function removeAnonymousCommentContent(): AnonymousCommentAction {
  return {
    type: REMOVE_ANONYMOUS_COMMENT_CONTENT,
  };
}

type ReducerType = string | null;

// Reducer

export function AnonymousCommentReducer(
  state = null,
  action: AnonymousCommentAction
): ReducerType {
  switch (action.type) {
    case ADD_ANONYMOUS_COMMENT_CONTENT:
      return action.anonymousCommentContent
        ? action.anonymousCommentContent
        : null;
    case REMOVE_ANONYMOUS_COMMENT_CONTENT:
      return null;
    default:
      return state;
  }
}
