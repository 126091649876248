import { FirebaseError } from 'firebase';

export const errorMessages: { [key: string]: string } = {
  'auth/user-not-found': 'Cet email ne correspond à aucun utilisateur',
  'auth/wrong-password': 'Le mot de passe est invalide',
  'auth/too-many-requests': 'Trop de tentatives... Réessaie plus tard',
};

export const CatchFirebaseFormError = (
  setError: (name: string, type: string, message?: string | undefined) => void,
  logMessage?: string
) => {
  return function(error: FirebaseError) {
    if (logMessage) {
      console.log(logMessage);
    }

    const errorCode = error.code;
    setError(
      errorCode,
      errorCode,
      errorCode in errorMessages ? errorMessages[errorCode] : error.message
    );
  };
};
