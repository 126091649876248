import React from 'react';
import { BaseStructure } from '../../components/structure/DefaultBackground';
import { HeaderBar } from '../../components/structure/HeaderBar';
import Error404Image from '../../../assets/images/errors/404.png';
import styled from 'styled-components';
import ActionButton from '../../components/buttons/ActionButton';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const Image = styled.img`
  width: 100%;
  background-color: white;
`;

const Title = styled.h1`
  font-size: 2em;
  color: #0056d1;
  letter-spacing: 0.0575em;
  text-align: center;
  font-variant: small-caps;
  margin: 0.5em;
`;

const Footer = styled.h1`
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
`;

const NonExistingShirtErrorPage: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <BaseStructure style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <HeaderBar />
      <Image src={Error404Image} alt="Erreur 404" />
      <Title>Il semble que ce teeshirt n'existe pas</Title>
      <Footer>
        <ActionButton
          variant="secondary"
          text="Retourner à l'accueil"
          onClick={() => dispatch(push('/'))}
        />
      </Footer>
    </BaseStructure>
  );
};

export default NonExistingShirtErrorPage;
