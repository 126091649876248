import React from 'react';
import firebase from 'firebase/app';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import '@firebase/auth';
import 'firebase/auth';
import 'firebase/firestore';

// Locals imports
import configureStore from './redux/store';
import fbConfig from './firebaseConfig';
import LoginPage from './views/pages/user/LoginPage';
import SettingsPage from './views/pages/settings/SettingsPage';
import RegisterPage from './views/pages/user/RegisterPage';
import ResetPage from './views/pages/user/ResetPage';
import {
  AuthIsLoaded,
  LoggedRoute,
  SignOut,
  NotLogged,
  IsLogged,
} from './utils/AuthUtils';
// Styles imports
import './App.scss';
import { ROUTES } from './constants/routes';
import { SpecificUserControllerFromRoute } from './controllers/UserHooks';
import moment from 'moment';
import { MomentFrench } from './constants/MomentFrench';
import { LoadingPage } from './views/pages/misc/LoadingPage';
import WelcomePage from './views/pages/welcome/WelcomePage';
import { ShirtsScanController } from './controllers/ShirtsController';
import MainChatsPage from './views/pages/chats/MainChatsPage';
import MainMapPage from './views/pages/map/MainMapPage';
import ChatPage from './views/pages/chats/ChatPage';
import LegalPage from './views/pages/legal/LegalPage';
import { loadState } from './redux/localStorage';
import NoStorePage from './views/pages/store/NoStorePage';

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true, // Get custom claims along with the profile
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);

// Initialize other services on firebase instance
firebase.firestore();

// Create store with reducers and initial state
const initialState = loadState();
export const history = createBrowserHistory();
const store = configureStore(initialState, history);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

moment.locale('fr', MomentFrench);

const App: React.FC = () => {
  return (
    <div className={'App'}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <ConnectedRouter history={history}>
            <AuthIsLoaded>
              <Switch>
                <LoggedRoute path={ROUTES.SETTINGS}>
                  <SettingsPage />
                </LoggedRoute>
                <Route path={ROUTES.LOGIN}>
                  <LoginPage />
                </Route>
                <Route path={ROUTES.REGISTER}>
                  <RegisterPage />
                </Route>
                <Route path={ROUTES.RESET}>
                  <ResetPage />
                </Route>
                <Route path={ROUTES.LEGAL}>
                  <LegalPage />
                </Route>
                <Route path={'/loading'}>
                  <LoadingPage />
                </Route>

                <Route path={ROUTES.STORE_SUCCESS}>
                  <NoStorePage />
                </Route>
                <Route path={ROUTES.STORE_CART} exact={true}>
                  <NoStorePage />
                </Route>
                <Route path={ROUTES.STORE}>
                  <NoStorePage />
                </Route>

                <LoggedRoute
                  path={ROUTES.CHATS + ':chatId'}
                  component={ChatPage}
                />
                <LoggedRoute path={ROUTES.CHATS}>
                  <MainChatsPage />
                </LoggedRoute>

                <Route
                  path={ROUTES.USERS + ':uid'}
                  component={SpecificUserControllerFromRoute}
                />
                <LoggedRoute path={ROUTES.USERS}>
                  <MainMapPage />
                </LoggedRoute>
                <Route
                  path={ROUTES.SHIRTS + ':code'}
                  component={ShirtsScanController}
                />
                <LoggedRoute path={ROUTES.SIGN_OUT}>
                  <SignOut />
                </LoggedRoute>
                <Route path={ROUTES.ROOT}>
                  <NotLogged>
                    <WelcomePage />
                  </NotLogged>
                  <IsLogged>
                    <MainMapPage />
                  </IsLogged>
                </Route>
              </Switch>
            </AuthIsLoaded>
          </ConnectedRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    </div>
  );
};

export default App;
