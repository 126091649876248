import React, { useRef } from 'react';
import {
  FlexStructure,
  PageContainer,
} from '../../components/structure/DefaultBackground';
import { HeaderBar } from '../../components/structure/HeaderBar';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { ROUTES } from '../../../constants/routes';
import { useDispatch } from 'react-redux';
import { Redirect, RouteComponentProps, useLocation } from 'react-router-dom';
import {
  createChat,
  sendChatMessage,
  updateRoomMemberInfos,
} from '../../../controllers/chats/ChatsController';
import { LoadingPage } from '../misc/LoadingPage';
import { addLoginRedirectionUrl } from '../../../redux/reducers/LoginRedirectionReducer';
import { useChatRoom } from '../../../controllers/chats/ChatRoomHooks';
import { SendMessageForm } from './SendMessageForm';
import ChatMessages from './ChatMessages';

//////

const ChatPage: React.FC<RouteComponentProps<{
  chatId: string;
}>> = ({ match }) => {
  const chatId = match.params.chatId;
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const location = useLocation();
  const loggedUser = firebase.auth().currentUser;

  const currentMessageInputRef = useRef<HTMLInputElement>(null);

  const chatRoom = useChatRoom(chatId);
  if (chatRoom === undefined) {
    return <LoadingPage />;
  }
  if (chatRoom === null) {
    createChat(chatId, firestore);
    return <LoadingPage />;
  }

  if (!loggedUser) {
    dispatch(addLoginRedirectionUrl(location.pathname));
    return <Redirect to={ROUTES.LOGIN} />;
  }

  updateRoomMemberInfos(loggedUser.uid, chatRoom.roomId, firestore);

  const sendMessage = () => {
    if (
      !currentMessageInputRef ||
      !currentMessageInputRef.current ||
      !currentMessageInputRef.current.value
    ) {
      return null;
    }
    sendChatMessage(
      chatId,
      loggedUser.uid,
      currentMessageInputRef.current.value,
      firestore
    );
    currentMessageInputRef.current.value = '';
  };

  return (
    <FlexStructure>
      <HeaderBar showBackButton={true} />
      <PageContainer>
        <ChatMessages chatId={chatId} chatRoom={chatRoom} />
      </PageContainer>
      <SendMessageForm ref={currentMessageInputRef} onSubmit={sendMessage} />
    </FlexStructure>
  );
};

export default ChatPage;
