import React, { useState } from 'react';
import { BaseStructure } from '../../components/structure/DefaultBackground';
import { HeaderBar } from '../../components/structure/HeaderBar';
import styled from 'styled-components';
import {
  BUTTON_PRIMARY_COLOR,
  BUTTON_PRIMARY_COLOR_TEXT,
  H_MARGIN,
  MAP_TEXT_COLOR,
} from '../../../constants/style';

import { BottomTabBar } from '../../components/structure/BottomTabBar';
import { useCurrentUser } from '../../../utils/AuthUtils';
import { CGV } from './CGV';
import { CGU } from './CGU';
import { MentionsLegales } from './MentionsLegales';
import { PolitiqueCookies } from './PolitiqueCookies';

// Images
const MarginBody = styled.div`
  padding: 2rem ${H_MARGIN};
`;

//////
const LegalSectionTitle = styled.h1`
  border: 0px;
  border-radius: 0.5rem 0.5rem 0 0;
  background: ${BUTTON_PRIMARY_COLOR};
  color: ${BUTTON_PRIMARY_COLOR_TEXT};
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  user-select: none;
  font-size: 1rem;
`;

const LegalSectionBody = styled.div`
  font-size: 0.75rem;
  color: ${MAP_TEXT_COLOR};

  padding: 0rem 0.5rem;
  border: 0px solid ${BUTTON_PRIMARY_COLOR};
  border-width: 0px 2px 2px 2px;

  max-height: 0;
  overflow: hidden;
  transition: padding-top 0.3s ease-out, padding-bottom 0.3s ease-out;

  p {
    line-height: 1rem;
    margin-bottom: 0.5rem;
    text-align: justify;
  }

  h1 {
    font-weight: bold;
    color: black;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
  }

  ul {
    list-style: square;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
  }
`;

const LegalSectionStyle = styled.div`
  &[aria-expanded='true'] {
    ${LegalSectionBody} {
      max-height: 100%;
      padding: 1rem 0.5rem;
      border-width: 0px 2px 2px 2px;
    }
  }
  margin-bottom: 1rem;
`;

export const LegalSection: React.FC<{ title: string }> = ({
  title,
  children,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <LegalSectionStyle aria-expanded={isOpen}>
      <LegalSectionTitle onClick={() => setOpen(!isOpen)}>
        {title}
      </LegalSectionTitle>
      <LegalSectionBody>{children}</LegalSectionBody>
    </LegalSectionStyle>
  );
};

const LegalPage: React.FC = () => {
  const loggedTeeWeeUser = useCurrentUser();

  return (
    <BaseStructure style={{ backgroundColor: '#f5f5f5' }}>
      <HeaderBar showBackButton={true} />
      <MarginBody>
        <MentionsLegales />
        <LegalSection title="Conditions générales d'utilisation">
          <CGU />
        </LegalSection>
        <LegalSection title="Conditions générales de vente">
          <CGV />
        </LegalSection>
        <PolitiqueCookies />
      </MarginBody>
      {loggedTeeWeeUser ? (
        <BottomTabBar currentUser={loggedTeeWeeUser} />
      ) : null}
    </BaseStructure>
  );
};

export default LegalPage;
