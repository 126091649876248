import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  DEFAULT_PROFILE_PICTURE_URL,
  H_MARGIN,
  MAX_WIDTH,
} from '../../../constants/style';
import { useFirebase } from 'react-redux-firebase';
import { ROUTES } from '../../../constants/routes';
import { Redirect } from 'react-router-dom';
import { LoadingPage } from '../misc/LoadingPage';
import { useChatMessages } from '../../../controllers/chats/ChatMessagesHooks';
import { useUsersFromUids } from '../../../controllers/UserHooks';
import { ChatRoomModel } from '../../../models/chat/ChatRoomModel';
import { TeeWeeUserModel } from '../../../models/TeeWeeUserModel';
import { ChatMessageModel } from '../../../models/chat/ChatMessageModel';
import { EmptyTeeWeeUser } from '../../../models/TeeWeeUserModel';

// Images
const Messages = styled.div`
  padding: 0 ${H_MARGIN};
  padding-top: ${H_MARGIN};
  width: 100%;
  max-width: ${MAX_WIDTH};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const MessageFromMeContainer = styled.div`
  box-sizing: border-box;
  margin: 0 0 0.5rem 4rem;

  text-align: right;
  display: flex;
  flex-direction: row-reverse;
`;
const MessageFromMeContent = styled.p`
  border-radius: ${H_MARGIN} ${H_MARGIN} 0 ${H_MARGIN};
  padding: ${H_MARGIN};

  color: #ffffff;
  background-color: #0056d1;

  font-weight: 500;
  line-height: 1.38;
`;

//////

const MessageFromOtherContainer = styled.div`
  box-sizing: border-box;
  margin: 0rem 3rem 1rem 0;

  display: flex;
`;

const MessageFromOtherProfile = styled.img`
  box-sizing: border-box;
  display: block;
  border-radius: 50%;
  border: solid 3.4px #ffffff;
  box-shadow: 0 3px 27px 0 rgba(0, 0, 0, 0.08);

  flex: none;

  width: 2.5rem;
  height: auto;
  align-self: flex-end;
`;

const MessageFromOtherContent = styled.p`
  box-sizing: border-box;
  border-radius: ${H_MARGIN} ${H_MARGIN} ${H_MARGIN} 0;
  padding: ${H_MARGIN};
  margin-left: 1rem;

  color: #262628;
  background-color: #e8e8e8;

  font-weight: 500;
  line-height: 1.38;
`;

const MessageFromOtherContentWithoutProfile = styled(MessageFromOtherContent)`
  margin-left: 3.5rem;
`;

const MessageFromOther: React.FC<{
  user: TeeWeeUserModel;
  message: ChatMessageModel;
  showProfile?: boolean;
}> = ({ user, message, showProfile = true }) => {
  return (
    <MessageFromOtherContainer
      style={{ marginBottom: showProfile ? '1rem' : '0.5rem' }}
    >
      {showProfile ? (
        <>
          <MessageFromOtherProfile
            src={user.photoURL || DEFAULT_PROFILE_PICTURE_URL}
            alt={user.username}
          />
          <MessageFromOtherContent>{message.content}</MessageFromOtherContent>
        </>
      ) : (
        <MessageFromOtherContentWithoutProfile>
          {message.content}
        </MessageFromOtherContentWithoutProfile>
      )}
    </MessageFromOtherContainer>
  );
};
//////

const ChatMessages: React.FC<{ chatId: string; chatRoom: ChatRoomModel }> = ({
  chatId,
  chatRoom,
}) => {
  const firebase = useFirebase();
  const loggedUser = firebase.auth().currentUser;
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [firstRender, setFirstRender] = useState<boolean>(true);

  const chatMessages = useChatMessages(chatId);
  const users = useUsersFromUids(chatRoom.members);

  if (!chatMessages || !users) {
    return <LoadingPage />;
  }

  if (!loggedUser) {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  if (messagesEndRef.current) {
    messagesEndRef.current.scrollIntoView(
      firstRender ? { behavior: 'smooth' } : {}
    );
    if (firstRender) {
      setFirstRender(false);
    }
  }

  const messagesArray = Object.entries(chatMessages);
  messagesArray.sort(([, aMessage], [, bMessage]) => {
    if (!aMessage.sentAt) {
      return 1;
    } else if (!bMessage.sentAt) {
      return -1;
    }
    return aMessage.sentAt.seconds - bMessage.sentAt.seconds;
  });

  let previousUser: TeeWeeUserModel | null = null;

  const buildMessage = (mId: string, message: ChatMessageModel) => {
    const isSame = previousUser && previousUser.uid === message.fromUid;

    previousUser = users[message.fromUid]
      ? users[message.fromUid]
      : EmptyTeeWeeUser;

    if (message.fromUid === loggedUser.uid) {
      return (
        <MessageFromMeContainer
          key={mId}
          style={{ marginBottom: !isSame ? '1rem' : '0.5rem' }}
        >
          <MessageFromMeContent>{message.content}</MessageFromMeContent>
        </MessageFromMeContainer>
      );
    } else {
      return (
        <MessageFromOther
          key={mId}
          user={
            users[message.fromUid] ? users[message.fromUid] : EmptyTeeWeeUser
          }
          message={message}
          showProfile={!isSame}
        />
      );
    }
  };

  return (
    <Messages>
      {messagesArray
        .reverse()
        .map(([mId, message]) => buildMessage(mId, message))
        .reverse()}
      <div ref={messagesEndRef} />
    </Messages>
  );
};

export default ChatMessages;
