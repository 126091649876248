import styled from 'styled-components';
import { H_MARGIN, BOTTOM_ROUNDED_BACKGROUND } from '../../../constants/style';

/**
 * Usage info: add a paddingBottom of 6vh on the previous division
 */

export const BottomRounded = styled.div`
  background-color: ${BOTTOM_ROUNDED_BACKGROUND};
  z-index: 101;

  border-radius: 3vh 3vh 0 0;
  padding: 6vh ${H_MARGIN};
  transform: translateY(-6vh);
  box-sizing: border-box;
`;
