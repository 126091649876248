import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  text-transform: uppercase;
  font-size: 0.8em;
  color: #0056d1;
  letter-spacing: 0.0575em;
  display: flex;
  font-weight: bold;
  flex-direction: column;
`;

interface InputLabelProps {
  text: string;
  style?: React.CSSProperties;
}

const InputLabel: React.FC<InputLabelProps> = ({ style, text, children }) => {
  return (
    <Label style={style}>
      {text}
      {children}
    </Label>
  );
};
export default InputLabel;
