const getBlob = (
  quality: number,
  canvas: HTMLCanvasElement,
  imageType: string
) =>
  new Promise<Blob>((resolve, reject) => {
    const setBlob = (newBlob: Blob | null) => {
      if (newBlob != null) {
        resolve(newBlob);
      } else {
        reject();
      }
    };
    canvas.toBlob(setBlob, imageType, quality);
  });

async function resizeBlob(
  originalBlob: Blob,
  maxSizeBytes: number,
  maxWidth = 1920,
  maxHeight = 1920
) {
  const imageType = originalBlob.type;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const image = new Image();

  if (ctx == null) {
    throw new TypeError('Context is null');
  }

  await new Promise(resolve => {
    image.onload = function() {
      console.log(`Infos loaded: ${image.width}x${image.height}`);
      resolve();
    };

    image.src = URL.createObjectURL(originalBlob);
  });

  ctx.drawImage(image, 0, 0);

  let width = image.width;
  let height = image.height;
  let start = 0;
  let end = 1;
  let last: number | null = null;
  let accepted: Blob = originalBlob;
  let blob: Blob = originalBlob;

  // keep portration
  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width *= maxHeight / height;
      height = maxHeight;
    }
  }
  canvas.width = width;
  canvas.height = height;
  // Scaling to max size
  ctx.drawImage(image, 0, 0, width, height);

  if (blob.size < maxSizeBytes) {
    //No quality change needed
    return URL.createObjectURL(blob);
  }

  // Binary search for the right size (Can be optimized)
  let keepGoing = true;
  while (keepGoing) {
    const mid = Math.round(((start + end) / 2) * 100) / 100;
    if (mid === last) {
      keepGoing = false;
      break;
    }
    last = mid;
    blob = await getBlob(mid, canvas, imageType);

    // Editing quality
    if (blob.size > maxSizeBytes) {
      end = mid;
    }
    if (blob.size < maxSizeBytes) {
      start = mid;
      accepted = blob;
    }
  }

  return URL.createObjectURL(accepted);
}

async function resizeBase64(
  imageUrlBase64: string,
  maxSizeBytes: number,
  maxWidth = 1920,
  maxHeight = 1920
) {
  const imageType = imageUrlBase64.split(':', 2)[1].split(';', 2)[0];

  const base64image = new Image();
  await new Promise(resolve => {
    base64image.onload = function() {
      resolve();
    };

    base64image.src = imageUrlBase64;
  });

  const canvas = document.createElement('canvas');
  canvas.width = base64image.width;
  canvas.height = base64image.height;
  const ctx = canvas.getContext('2d');

  if (ctx == null) {
    throw new TypeError('Context is null');
  }

  ctx.drawImage(base64image, 0, 0, base64image.width, base64image.height);
  const blob = await getBlob(1, canvas, imageType);

  return resizeBlob(blob, maxSizeBytes, maxWidth, maxHeight);
}

export async function compressImage(
  imageUrl: string,
  maxSizeBytes: number,
  maxWidth = 1920,
  maxHeight = 1920
) {
  if (imageUrl.startsWith('blob')) {
    const blob = await fetch(imageUrl).then(r => r.blob());
    return resizeBlob(blob, maxSizeBytes, maxWidth, maxHeight);
  } else {
    console.log('Is is a B64');
    return resizeBase64(imageUrl, maxSizeBytes, maxWidth, maxHeight);
  }
}
