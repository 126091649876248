import React from 'react';
import { ShirtModel } from '../../../models/ShirtModel';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { HeaderBar } from '../../components/structure/HeaderBar';
import { DefaultBackground } from '../../components/structure/DefaultBackground';
import { ShirtBig } from '../../../assets/images/imagesPNG';
import styled from 'styled-components';
import { FireworksAnimation } from '../../components/misc/Fireworks';
import ActionButton from '../../components/buttons/ActionButton';
import { H_MARGIN, TITLE_TEXT_COLOR } from '../../../constants/style';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../../../constants/routes';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { addLoginRedirectionUrl } from '../../../redux/reducers/LoginRedirectionReducer';
import { linkShirt } from '../../../controllers/ShirtsController';

const Body = styled.div`
  text-align: center;
  margin-left: ${H_MARGIN};
  margin-right: ${H_MARGIN};

  * {
    margin-bottom: 1em;
  }

  h1 {
    font-size: 150%;
    color: ${TITLE_TEXT_COLOR};

    margin-top: 1em;
  }
`;

const ButtonList = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 4em;
`;

const Image = styled.img`
  width: 80%;
  display: block;
  margin: auto;
`;

const ShirtLinkingPage: React.FC<{ shirt: ShirtModel }> = ({ shirt }) => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const loggedUser = firebase.auth().currentUser;
  const dispatch = useDispatch();
  const location = useLocation();

  if (!loggedUser) {
    dispatch(addLoginRedirectionUrl(location.pathname));
  } else if (!shirt.owner) {
    //Link the shirt
    linkShirt(shirt.code, loggedUser.uid, firestore);
  }

  return (
    <DefaultBackground style={{ backgroundColor: '#f7f7f7' }}>
      <HeaderBar />
      <Body>
        <Image src={ShirtBig} alt="Teeshirt Teewee" />
        {!loggedUser ? (
          <>
            <h1>Tu y es presque !</h1>
            <p>Connecte-toi pour lier le teeshirt</p>
            <ButtonList>
              <ActionButton
                variant="primary"
                text="Se connecter"
                onClick={() => dispatch(push(ROUTES.LOGIN))}
              />
              <ActionButton
                variant="secondary"
                text="S'inscrire"
                onClick={() => dispatch(push(ROUTES.REGISTER))}
              />
            </ButtonList>
          </>
        ) : (
          <>
            <h1>Félicitations !</h1>
            <p>Le teeshirt a été ajouté à ton vestiaire</p>
            <ActionButton
              variant="primary"
              text="Ouvrir le vestiaire"
              style={{ width: 'auto', marginTop: '4em' }}
              onClick={() => dispatch(push(ROUTES.SETTINGS_VESTIAIRE))}
            />
            <FireworksAnimation />
          </>
        )}
      </Body>
    </DefaultBackground>
  );
};

export default ShirtLinkingPage;
