import React from 'react';
import ShapedBackground from '../../components/structure/ShapedBackground';
import RegisterForm from '../../components/forms/RegisterForm';

const RegisterPage: React.FC = () => {
  return (
    <ShapedBackground>
      <RegisterForm />
    </ShapedBackground>
  );
};

export default RegisterPage;
