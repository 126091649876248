import {
  LogoFacebook,
  LogoInstagram,
  LogoSnapchat,
  LogoTwitter,
  LogoCustom,
} from '../assets/images/imagesSVG';
import {
  SocialNetworks,
  SocialNetworksNames,
} from '../models/SocialNetworksModel';

/**
 * Dict cointaining the URL to prepend to the username given by the user. The algorithm to determine the URLs is :
 * IF (username given) starts with ((http|https)://(www.)?[SocialNetworkURL]/):
 *  return (username given)
 * ELSE
 *  return (https://[SocialNetworkURL][username given])
 */
function escapeRegExp(strToEscape: string) {
  return strToEscape.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

const getSocialNetworkRegexp = (url: string) => {
  const regexpStr = `(https?:\\/\\/(www\\.)?)?${escapeRegExp(url)}\\/`;
  return new RegExp(regexpStr);
};

export type SocialNetworksInfos = {
  [name in SocialNetworksNames]: { url: string; regexp: RegExp; logo: string };
};

export const SOCIAL_NETWORKS_INFOS: SocialNetworksInfos = {
  instagram: {
    url: 'instagram.com',
    regexp: getSocialNetworkRegexp('instagram.com'),
    logo: LogoInstagram,
  },
  twitter: {
    url: 'twitter.com',
    regexp: getSocialNetworkRegexp('twitter.com'),
    logo: LogoTwitter,
  },
  facebook: {
    url: 'facebook.com',
    regexp: getSocialNetworkRegexp('facebook.com'),
    logo: LogoFacebook,
  },
  snapchat: {
    url: 'snapchat.com/add',
    regexp: getSocialNetworkRegexp('snapchat.com/add'),
    logo: LogoSnapchat,
  },
  custom: { url: '', regexp: getSocialNetworkRegexp(''), logo: LogoCustom },
};

export const getSocialNetworksURLs = (socialNetwork?: SocialNetworks) => {
  const returnValue: SocialNetworks = {};
  if (socialNetwork) {
    for (const keyOriginal in socialNetwork) {
      const key = keyOriginal as SocialNetworksNames;
      const value = socialNetwork[key];
      if (!value) {
        continue;
      } else if (value.match(SOCIAL_NETWORKS_INFOS[key].regexp)) {
        returnValue[key] = value.startsWith('http')
          ? value
          : 'https://' + value;
      } else {
        returnValue[key] = `https://${SOCIAL_NETWORKS_INFOS[key].url}/${value}`;
      }
    }
  }
  return returnValue;
};
