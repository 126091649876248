import React, { useEffect, useState } from 'react';
import {
  FlexStructure,
  PageContainer,
} from '../../components/structure/DefaultBackground';
import { HeaderBar } from '../../components/structure/HeaderBar';
import styled from 'styled-components';
import { DEFAULT_PROFILE_PICTURE_URL } from '../../../constants/style';
import { useFirebase } from 'react-redux-firebase';
import { PictoGear } from '../../../assets/images/imagesSVG';
import { ROUTES } from '../../../constants/routes';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { BottomTabBar } from '../../components/structure/BottomTabBar';
import { useCurrentUser } from '../../../utils/AuthUtils';
import { CircleInfo, getMaxUserForRadius, UserCircle } from './UserCircle';
import {
  MAP_SIZE_OTHER_PROFILE,
  MAP_UNIT,
  MAP_SIZE_CURRENT,
  MAP_SPACE_BETWEEN_CIRCLES,
  mapShouldUseWidth,
} from './MapConstants';
import { centerToCurrent, MapCenterToCurrent } from './MapCenterToCurrent';
import { MapProfilePicture, MapProfileStyle } from './MapProfile';
import { Link } from 'react-router-dom';
import { LoadingPage } from '../misc/LoadingPage';
import { isBrowser } from 'react-device-detect';

// Images
const CurrentProfile = styled(MapProfileStyle)`
  width: ${MAP_SIZE_CURRENT + MAP_UNIT};
  img {
    box-shadow: 0 6px 52px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    height: auto;
  }
`;

const Map = styled.div`
  padding: 0;
  position: relative;
  min-width: ${mapShouldUseWidth ? '100vw' : '100vh'};
  min-height: ${mapShouldUseWidth ? '150vw' : '100vh'};
  margin-bottom: 6rem;
`;

const MapContainer = styled.div`
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  scroll-behavior: smooth;
`;

////

const loadUsersCircles = (users: string[]) => {
  const initialRadius =
    (MAP_SIZE_CURRENT + MAP_SIZE_OTHER_PROFILE) / 2 + MAP_SPACE_BETWEEN_CIRCLES;

  const usersCircles: CircleInfo[] = [];

  let currentRadius = initialRadius;
  while (users.length > 0) {
    usersCircles.push({
      radius: currentRadius,
      startingAngle: 2 * Math.PI * Math.random(),
      users: [],
    });
    const currentCircle = usersCircles[usersCircles.length - 1];

    const maxOnCurrentCircle = getMaxUserForRadius(currentRadius);
    for (let i = 0; i < maxOnCurrentCircle && users.length > 0; i++) {
      const user = users.shift();
      if (!user) {
        break;
      }
      currentCircle.users.push(user);
    }

    currentRadius += MAP_SPACE_BETWEEN_CIRCLES + MAP_SIZE_OTHER_PROFILE;
  }
  return usersCircles;
};

const MainMapPage: React.FC = () => {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const loggedUser = firebase.auth().currentUser;
  const loggedTeeWeeUser = useCurrentUser();

  // Make current user scrollable to
  const mapContainerRef = React.useRef<HTMLDivElement>(null);
  const currentUserRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => centerToCurrent(mapContainerRef, currentUserRef));

  // User circles
  const [usersCircles, setUsersCircles] = useState<CircleInfo[]>([]);

  if (!loggedUser || !loggedTeeWeeUser) {
    return null;
  }

  // eslint-disable-next-line no-undef
  const users = Array.from(
    // eslint-disable-next-line no-undef
    new Set(
      (loggedTeeWeeUser.scanned ? loggedTeeWeeUser.scanned : [])
        .concat(loggedTeeWeeUser.scannedBy ? loggedTeeWeeUser.scannedBy : [])
        .concat(loggedTeeWeeUser.canTalkTo ? loggedTeeWeeUser.canTalkTo : [])
    )
  );

  while (users.length < 6) {
    users.push('anonymous' + users.length);
  }

  if (usersCircles.length === 0) {
    setUsersCircles(loadUsersCircles(users));
    return <LoadingPage />;
  }

  const maxRadius =
    usersCircles[usersCircles.length - 1].radius +
    MAP_SPACE_BETWEEN_CIRCLES +
    MAP_SIZE_OTHER_PROFILE;
  const circlesOrigin = Math.max(50, maxRadius);

  return (
    <FlexStructure style={{ maxWidth: isBrowser ? '100%' : 'auto' }}>
      <HeaderBar
        showBackButton={false}
        button={{
          src: PictoGear,
          onClick: () => dispatch(push(ROUTES.SETTINGS_MAIN)),
        }}
      />
      <PageContainer style={{ overflow: 'hidden', display: 'flex' }}>
        <MapContainer ref={mapContainerRef}>
          <Map
            style={{
              width: 2 * maxRadius + MAP_UNIT,
              height: 2 * maxRadius + MAP_UNIT,
            }}
          >
            <CurrentProfile
              ref={currentUserRef}
              style={{
                top: circlesOrigin + MAP_UNIT,
                left: circlesOrigin + MAP_UNIT,
              }}
            >
              <Link to={ROUTES.USERS + loggedUser.uid}>
                <MapProfilePicture
                  src={loggedTeeWeeUser.photoURL || DEFAULT_PROFILE_PICTURE_URL}
                  alt={
                    "Image de profil de l'utilisateur " +
                    loggedTeeWeeUser.username
                  }
                />
              </Link>
            </CurrentProfile>
            {usersCircles.map(circleInfo => (
              <UserCircle
                key={circleInfo.radius}
                info={circleInfo}
                origin={circlesOrigin}
              />
            ))}
          </Map>
        </MapContainer>
      </PageContainer>
      <BottomTabBar currentUser={loggedTeeWeeUser}>
        <MapCenterToCurrent
          centerFunction={() =>
            centerToCurrent(mapContainerRef, currentUserRef)
          }
        />
      </BottomTabBar>
    </FlexStructure>
  );
};

export default MainMapPage;
