import React, { Component } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestore,
  useFirebase,
} from 'react-redux-firebase';
import { State } from '../redux/reducer';
import { COLLECTIONS } from '../constants/collections';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import { LoadingPage } from '../views/pages/misc/LoadingPage';
import { TeeWeeUserModel } from '../models/TeeWeeUserModel';
import { ROUTES } from '../constants/routes';
import { consolidateTeeWeeUser } from '../controllers/UserController';

export const AuthIsLoaded: React.FC = props => {
  const auth = useSelector((state: State) => state.firebase.auth);
  if (!isLoaded(auth)) return <LoadingPage />;
  return <div>{props.children}</div>;
};

export const useCurrentUser = () => {
  const auth = useSelector((state: State) => state.firebase.auth);
  const firestore = useFirestore();
  const usersStore = useSelector(
    (state: State) =>
      state.firestore.data[COLLECTIONS.USERS] as {
        [id: string]: TeeWeeUserModel;
      }
  );

  if (auth.isEmpty) {
    return undefined;
  } else if (!usersStore || !usersStore[auth.uid]) {
    // Data is not loaded
    const storeDocument: ReduxFirestoreQuerySetting = {
      collection: COLLECTIONS.USERS,
      doc: auth.uid,
    };
    firestore.get(storeDocument);
    return undefined;
  } else {
    return consolidateTeeWeeUser(usersStore[auth.uid] as TeeWeeUserModel);
  }
};

export const IsLogged: React.FC = props => {
  const auth = useSelector((state: State) => state.firebase.auth);

  if (auth.isEmpty) {
    return <></>;
  } else {
    return <div>{props.children}</div>;
  }
};

export const NotLogged: React.FC = props => {
  const auth = useSelector((state: State) => state.firebase.auth);
  if (!auth.isEmpty) {
    return <></>;
  } else {
    return <div>{props.children}</div>;
  }
};

export const LoggedRoute: React.FC<RouteProps> = routeProps => {
  const firebase = useFirebase();
  const currentUser = firebase.auth().currentUser;

  if (!currentUser) {
    return <Redirect to="/" />;
  } else {
    return <Route {...routeProps} render={props => <Component {...props} />} />;
  }
};

export const SignOut: React.FC = () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  firebase
    .auth()
    .signOut()
    .then(() => {
      console.log('Disconnect');
      dispatch(push(ROUTES.ROOT));
    });

  return <>Signing out...</>;
};
