import React from 'react';
import styled from 'styled-components';
import BaseButton from './buttons/BaseButton';

const StyledButton = styled(BaseButton)`
  color: #afafaf;
  font-size: 1em;
`;

interface StyledButtonProps {
  text: string;
  onClick?: () => void;
}

const LinkButton: React.FC<StyledButtonProps> = ({ text, onClick }) => {
  return (
    <StyledButton onClick={onClick} type={`button`}>
      {text}
    </StyledButton>
  );
};

export default LinkButton;
