import { CartItemModel } from '../../models/store/CartModel';

const ADD_ITEM_TO_CART = '@@teewee/ADD_ITEM_TO_CART';
const REMOVE_ITEM_FROM_CART = '@@teewee/REMOVE_ITEM_FROM_CART';
const EMPTY_CART = '@@teewee/EMPTY_CART';

type ReducerType = CartItemModel[] | null;
interface Action {
  type: string;
  item?: CartItemModel;
  index?: number;
}

export function addItemToCart(item: CartItemModel): Action {
  return {
    type: ADD_ITEM_TO_CART,
    item,
  };
}

export function removeItemFromCart(index: number): Action {
  return {
    type: REMOVE_ITEM_FROM_CART,
    index,
  };
}

export function emptyCart(): Action {
  return {
    type: EMPTY_CART,
  };
}

export function CartManagementReducer(
  state: ReducerType = null,
  action: Action
): ReducerType {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      if (!action.item) {
        return null;
      }
      return state ? state.concat(action.item) : [action.item];
    case REMOVE_ITEM_FROM_CART:
      if (state && action.index !== undefined) {
        state.splice(action.index, 1);
      }
      if (!state) {
        return state;
      } else {
        return state.slice();
      }
    case EMPTY_CART:
      return null;
    default:
      return state;
  }
}
