import styled from 'styled-components';

const NotificationIndicator = styled.span`
  background-color: #fa3b15;
  border-radius: 50%;
  display: inline-block;
  height: 25%;
  width: 25%;
  position: absolute;
  top: 17%;
  right: 15%;
  transform: translate(50%, -50%);
`;

export default NotificationIndicator;
