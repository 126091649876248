import React from 'react';
import { TeeWeeUserModel } from '../../../models/TeeWeeUserModel';
import styled from 'styled-components';
import { StatusEntries, CommentEntries } from '../../../redux/FirestoreData';
import { StatusModel } from '../../../models/StatusModel';
import { H_MARGIN, TIMELINE_MARGIN } from '../../../constants/style';
import { CommentModel } from '../../../models/CommentModel';
import { FirestoreDateToDate } from '../../../models/FirestoreFields';
import {
  CommentCard,
  UserCommentCard,
} from '../../components/cards/CommentCard';
import { StatusCard } from '../../components/cards/StatusCard';

const CommentSeparator = styled.div`
  height: 0px;
  opacity: 0.05;
  background-color: #000000;
  content: '';
  margin: ${TIMELINE_MARGIN} ${H_MARGIN};
`;
export const TimelineSection: React.FC<{
  status: StatusEntries;
  comments: CommentEntries;
  isMyPage: boolean;
  pageOwner: TeeWeeUserModel;
  currentStatusId?: string;
}> = ({ status, comments, currentStatusId, isMyPage, pageOwner }) => {
  // We need to merge status (except the last one) and comments to build the timeline
  const oldStatus: (CommentModel | StatusModel)[] = Object.keys(status)
    .filter(key => key !== currentStatusId)
    .map(key => Object.assign({}, status[key], { id: key }));

  const commentsWithId: CommentModel[] = comments
    ? Object.keys(comments).map(key =>
        Object.assign({}, comments[key], { id: key })
      )
    : [];

  const merged: (CommentModel | StatusModel)[] = oldStatus.concat(
    commentsWithId
  );

  merged.sort((a, b) => {
    // If same type, sort by created at
    const aIsComment = (a as CommentModel).targetStatus;
    const bIsComment = (b as CommentModel).targetStatus;

    if (aIsComment !== bIsComment) {
      const comment = (aIsComment ? a : b) as CommentModel;
      const status = (aIsComment ? b : a) as StatusModel;

      if (comment.targetStatus === status.id) {
        return -1;
      }
    }

    return (
      FirestoreDateToDate(b.createdAt).getTime() -
      FirestoreDateToDate(a.createdAt).getTime()
    );
  });

  let isFirstCommentForStatus = true;

  return (
    <ul style={{ paddingBottom: '1rem' }}>
      {merged.map(element => {
        const key = element.createdAt
          ? element.createdAt.nanoseconds.toString() +
            element.createdAt.seconds.toString()
          : 'null';

        if (Object.keys(element).length < 2) {
          // Deleted comment
          return null;
        } else if ((element as CommentModel).targetStatus) {
          const comment = element as CommentModel;
          const firstComment = isFirstCommentForStatus;
          isFirstCommentForStatus = false;

          return (
            <>
              {!firstComment && <CommentSeparator />}
              {comment.fromUid ? (
                <UserCommentCard
                  commentId={comment.id ? comment.id : 'ERROR'}
                  isMyPage={isMyPage}
                  key={key}
                  comment={comment}
                  fromUid={comment.fromUid}
                  pageOwner={pageOwner}
                />
              ) : (
                <CommentCard
                  commentId={comment.id ? comment.id : 'ERROR'}
                  isMyPage={isMyPage}
                  key={key}
                  comment={comment}
                  pageOwner={pageOwner}
                />
              )}
            </>
          );
        } else {
          isFirstCommentForStatus = true;
          return (
            <StatusCard
              key={key}
              status={element as StatusModel}
              pageOwner={pageOwner}
              isMyPage={isMyPage}
            />
          );
        }
      })}
    </ul>
  );
};
