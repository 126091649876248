import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants/collections';
import { ChatRoomModel } from '../../models/chat/ChatRoomModel';
import { State } from '../../redux/reducer';

export const useChatRoom = (roomId: string) => {
  const storeDocument: ReduxFirestoreQuerySetting = {
    collection: COLLECTIONS.CHAT_ROOMS,
    doc: roomId,
  };

  useFirestoreConnect([storeDocument]);

  const chatRoom = useSelector((state: State) => {
    const collection = state.firestore.data[COLLECTIONS.CHAT_ROOMS] as {
      [id: string]: ChatRoomModel;
    };
    return collection && (collection[roomId] as ChatRoomModel);
  });

  if (!chatRoom && !isLoaded(chatRoom)) {
    return undefined;
  }

  if (!chatRoom && isLoaded(chatRoom)) {
    return null;
  }

  return chatRoom;
};

export const useChatsRooms = (roomsIds: string[]) => {
  const storeDocuments: ReduxFirestoreQuerySetting[] = roomsIds.map(id => {
    return {
      collection: COLLECTIONS.CHAT_ROOMS,
      doc: id,
    };
  });

  useFirestoreConnect(storeDocuments);

  const chatsRooms = useSelector((state: State) => {
    if (!state.firestore.data[COLLECTIONS.CHAT_ROOMS]) {
      return undefined;
    }

    const collection = state.firestore.data[COLLECTIONS.CHAT_ROOMS] as {
      [id: string]: ChatRoomModel;
    };
    const watchedRooms: { [id: string]: ChatRoomModel } = {};
    for (const idIdx in roomsIds) {
      const roomId = roomsIds[idIdx];
      if (collection && collection[roomId]) {
        watchedRooms[roomId] = collection[roomId] as ChatRoomModel;
      }
    }

    return watchedRooms;
  });

  if (!chatsRooms && !isLoaded(chatsRooms)) {
    return undefined;
  }

  if (!chatsRooms && isLoaded(chatsRooms)) {
    return null;
  }

  return chatsRooms;
};
