export const COLLECTIONS = {
  USERS: 'users',
  CHAT_ROOMS: 'chatsRooms',
  CHAT_MESSAGES: 'messages',
  CHAT_MEMBERS_INFOS: 'membersInfos',
  COMMENTS: 'comments',
  STATUS: 'status',
  SHIRTS: 'shirts',
  PRODUCTS: 'products',
};
