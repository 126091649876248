let fbConfig: {};
if (process.env.REACT_APP_ENV === 'Production') {
  fbConfig = {
    apiKey: 'AIzaSyAAjp1cdTKkkASBM1lYRWDAnpMa56108rM',
    authDomain: 'teewee-app.firebaseapp.com',
    databaseURL: 'https://teewee-app.firebaseio.com',
    projectId: 'teewee-app',
    storageBucket: 'teewee-app.appspot.com',
    messagingSenderId: '564593583444',
    appId: '1:564593583444:web:89b6ea8a4dddb6b6b9d4c1',
  };
} else if (process.env.REACT_APP_ENV === 'Gomez') {
  fbConfig = {
    apiKey: 'AIzaSyDa47kJHtUi3Cz9RNGs1FFm_N2nHLXEkpE',
    authDomain: 'teewee-app-ggo.firebaseapp.com',
    databaseURL: 'https://teewee-app-ggo.firebaseio.com',
    projectId: 'teewee-app-ggo',
    storageBucket: 'teewee-app-ggo.appspot.com',
    messagingSenderId: '436272925758',
    appId: '1:436272925758:web:a61b4f2d8019f0c602956f',
  };
} else if (process.env.REACT_APP_ENV === 'Staging') {
  fbConfig = {
    apiKey: 'AIzaSyDxEWLeqg4AtXG5rs3-dhOtJtbf9zjrl3g',
    authDomain: 'teewee-app-staging.firebaseapp.com',
    databaseURL: 'https://teewee-app-staging.firebaseio.com',
    projectId: 'teewee-app-staging',
    storageBucket: 'teewee-app-staging.appspot.com',
    messagingSenderId: '1020946796412',
    appId: '1:1020946796412:web:149d9cc8607305592c1ef9',
  };
} else {
  fbConfig = {};
}

export default fbConfig;
