import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import { ButtonCard, ButtonIcon } from '../buttons/ButtonCard';
import { PictoCross, PictoTick } from '../../../assets/images/imagesSVG';
import { getCroppedImg } from '../../../utils/CropUtils';
import { Area } from 'react-easy-crop/types';
import {
  CARDS_IMAGE_WIDTH,
  CARDS_IMAGE_HEIGHT,
} from '../../../constants/style';

const CropPopupContainer = styled.div`
  background-color: #000000de;
  height: 100vh;
  max-height: 100vh;
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 100;

  ${ButtonCard} {
    position: fixed;
    bottom: 1rem;
    margin: 0;
  }

  ${ButtonCard}:nth-child (2) {
    left: 1rem;
  }

  ${ButtonCard}:nth-child (3) {
    right: 1rem;
  }
`;

export const CropPopUp: React.FC<{
  imageUrl: string;
  aspect?: number;
  onExit: () => void;
  onComplete: (croppedUrl: string) => void;
}> = ({ imageUrl, aspect, onExit, onComplete }) => {
  console.log('Image: ', imageUrl);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (croppedAreaPixels === null) {
      throw new TypeError('CroppedAreaPixels must not be null');
    }

    try {
      const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels, 0);

      onComplete(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imageUrl, onComplete]);

  return (
    <CropPopupContainer
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Cropper
        image={imageUrl}
        crop={crop}
        zoom={zoom}
        aspect={aspect ? aspect : CARDS_IMAGE_WIDTH / CARDS_IMAGE_HEIGHT}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />

      <ButtonCard type={'button'} onClick={onExit}>
        <ButtonIcon src={PictoCross} />
      </ButtonCard>

      <ButtonCard type={'button'} onClick={showCroppedImage}>
        <ButtonIcon src={PictoTick} />
      </ButtonCard>
    </CropPopupContainer>
  );
};
