import DefaultProfile from '../assets/images/work/people.svg';

export const H_MARGIN_VALUE = 15;
export const H_MARGIN_UNIT = 'px';
export const H_MARGIN = H_MARGIN_VALUE + H_MARGIN_UNIT;
export const CARDS_TITLE_COLOR = '#262628';
export const CARDS_TEXT_COLOR = '#a6a6a6';
export const MAX_WIDTH = '500px';

export const TIMELINE_MARGIN = '1.5rem';

export const BUTTON_PRIMARY_COLOR = '#0056d1';
export const BUTTON_PRIMARY_COLOR_TEXT = 'white';
export const BUTTON_PRIMARY_COLOR_ACTIVE = 'rgba(0, 86, 209, 0.9)';
export const BUTTON_SECONDARY_COLOR = 'rgba(250, 59, 21, 0.04)';
export const BUTTON_SECONDARY_COLOR_ACTIVE = 'white';
export const BUTTON_SECONDARY_COLOR_TEXT = '#fa3b15';

export const BOTTOM_ROUNDED_BACKGROUND = '#f7f7f7';

export const CARDS_IMAGE_WIDTH = 500;
export const CARDS_IMAGE_HEIGHT = 313;

export const BACKGROUND_YELLOW = '#fdb022';

export const DEFAULT_PROFILE_PICTURE_URL = DefaultProfile;

export const TITLE_TEXT_COLOR = '#0056d1';
export const MAP_TEXT_COLOR = '#767676';
