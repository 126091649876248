import styled from 'styled-components';
import BaseButton from './BaseButton';

export const ButtonCard = styled(BaseButton)`
  background: white;
  margin-left: 3em;
  margin-top: 3em;
  position: relative;
  border-radius: 25%;
  height: 4em;
  width: 4em;
  line-height: unset;
  padding: 0;
`;

export const ButtonIcon = styled.img`
  width: 1.2em;
`;
