import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { H_MARGIN_VALUE, H_MARGIN_UNIT } from '../../../constants/style';
import { PictoBack } from '../../../assets/images/imagesSVG';
import { HeaderButton, HeaderButtonImg } from './HeaderBar';

const Container = styled.div`
  background-color: transparent;
  height: 11vh;
  padding-top: 1vh;
  display: flex;
  align-items: center;
  position: relative;
`;

export const BackButton = styled(HeaderButton)`
  left: ${2 * H_MARGIN_VALUE + H_MARGIN_UNIT};
  background-color: white;
`;

export const BackButtonBar: React.FC<{}> = () => {
  const history = useHistory();

  return (
    <Container>
      <BackButton onClick={history.goBack}>
        <HeaderButtonImg src={PictoBack} />
      </BackButton>
    </Container>
  );
};
