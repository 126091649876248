import React from 'react';
import ShapedBackground from '../../components/structure/ShapedBackground';
import LoginForm from '../../components/forms/LoginForm';

const LoginPage: React.FC = () => {
  return (
    <ShapedBackground>
      <LoginForm />
    </ShapedBackground>
  );
};

export default LoginPage;
