import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import BaseButton from '../buttons/BaseButton';

import LogoSvg from '../../../assets/images/logos/logo_rvb.svg';
import { useHistory } from 'react-router-dom';
import { H_MARGIN } from '../../../constants/style';
import NotificationIndicator from '../misc/NotificationIndicator';
import { PictoBack } from '../../../assets/images/imagesSVG';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const HeaderBarContainer = styled.div`
  background-color: white;
  height: 4rem;
  display: flex;
  align-items: center;
  position: relative;
  flex: none;
`;

export const HeaderButton = styled(BaseButton)`
  background-color: #a3a3a426;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 25%;

  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  position: absolute;
`;

const HeaderButtonLeft = styled(HeaderButton)`
  left: ${H_MARGIN};
`;

export const HeaderButtonRight = styled(HeaderButton)`
  right: ${H_MARGIN};
`;

export const HeaderButtonImg = styled.img`
  width: 1.3rem;
  margin: auto;
`;

export const HeaderButtonImgWB = styled(HeaderButtonImg)`
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
`;

const HeaderLogo = styled.img`
  margin: auto;
  max-height: 75%;
  max-width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export type HeaderButtonInfos = {
  src: string;
  hasNotifications?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const HeaderBar: React.FC<{
  showBackButton?: boolean;
  forceBackButton?: string;
  button?: HeaderButtonInfos;
}> = ({ showBackButton, forceBackButton, button }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <HeaderBarContainer>
      {showBackButton ? (
        <HeaderButtonLeft
          onClick={
            forceBackButton
              ? () => dispatch(push(forceBackButton))
              : history.goBack
          }
        >
          <HeaderButtonImgWB src={PictoBack} />
        </HeaderButtonLeft>
      ) : null}
      <HeaderLogo src={LogoSvg} />
      {button ? (
        <HeaderButtonRight onClick={button.onClick}>
          <HeaderButtonImg src={button.src} />
          {button.hasNotifications ? <NotificationIndicator /> : null}
        </HeaderButtonRight>
      ) : null}
    </HeaderBarContainer>
  );
};
