import React from 'react';
import styled, { keyframes } from 'styled-components';
import { BaseStructure } from '../structure/DefaultBackground';
import { MAX_WIDTH, H_MARGIN } from '../../../constants/style';

export const BottomAppearAnimation = (percent: number) => keyframes`
  from {
    top: 100vh;
  }

  to {
    top: ${percent + 'vh'};
  }
`;

const backgroundAnimation = keyframes`
  from {
    background-color: rgba(0,0,0,0);
  }

  to {
    background-color: rgba(0,0,0,0.5);
  }
`;

const animationProps = '500ms ease-out forwards';

export const BottomModalStyle = styled.div`
  position: absolute;
  width: 100vw;
  max-width: ${MAX_WIDTH};

  left: 50%;
  right: 0;
  bottom: 0;
  background-color: white;
  transform: translateX(-50%);
  z-index: 101;

  border-radius: 3vh 3vh 0 0;
  padding: 6vh ${H_MARGIN} 2vh ${H_MARGIN};
  box-sizing: border-box;
  animation: ${BottomAppearAnimation(30)} ${animationProps};

  overflow: scroll;
  scrollbar-width: none;
`;

export const BackgroundOpacifier = styled(BaseStructure)`
  position: fixed;
  width: 100vw;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
  z-index: 100;

  animation: ${backgroundAnimation} ${animationProps};
`;

export interface BottomModalProps {
  visible: boolean;
  disappearFunction: () => void;
}

export const BottomModal: React.FC<BottomModalProps> = ({
  visible,
  disappearFunction,
  children,
}) => {
  return visible ? (
    <BackgroundOpacifier onClick={e => disappearFunction()}>
      <BottomModalStyle onClick={e => e.stopPropagation()}>
        {children}
      </BottomModalStyle>
    </BackgroundOpacifier>
  ) : null;
};
