import { combineReducers } from 'redux';
import {
  firebaseReducer as firebase,
  FirebaseReducer,
  FirestoreReducer,
} from 'react-redux-firebase';
import { RouterState, connectRouter } from 'connected-react-router';
import { History } from 'history';
import { firestoreReducer } from 'redux-firestore';
import { FirestoreData } from './FirestoreData';
import { LoginRedirectionReducer } from './reducers/LoginRedirectionReducer';
import { AnonymousCommentReducer } from './reducers/AnonymousCommentReducer';
import { CartManagementReducer } from './reducers/CartManagementReducer';
import { CartItemModel } from '../models/store/CartModel';

const rootReducer = (history: History) =>
  combineReducers({
    firebase,
    firestore: firestoreReducer,
    router: connectRouter(history),
    loginRedirection: LoginRedirectionReducer,
    anonymousComment: AnonymousCommentReducer,
    cart: CartManagementReducer,
  });

export interface FirestoreTeeWeeReducer extends FirestoreReducer.Reducer {
  data: FirestoreData;
}

export interface State {
  firebase: FirebaseReducer.Reducer;
  firestore: FirestoreTeeWeeReducer;
  router: RouterState;
  loginRedirection?: string;
  anonymousComment?: string;
  cart?: CartItemModel[];
}

export default rootReducer;
