import React from 'react';
import { BaseStructure } from '../../components/structure/DefaultBackground';
import styled, { keyframes } from 'styled-components';
import { LogoSimpleT } from '../../../assets/images/imagesSVG';

const CenteredLogo = styled.img`
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const logoAnimation = keyframes`
  from {
    background-color: #fff;
  }

  to {
    background-color: #ccc;
  }
`;

const ShadowedStructure = styled(BaseStructure)`
  height: 100vh;
  opacity: 0.25;

  background-image: radial-gradient(
    circle at 50% 50%,
    #00000000,
    #00000000 10%,
    #00000022 30%,
    #000000aa 90%
  );

  width: 100%;
  max-width: 100vw;
`;

const BackgroundAplat = styled(BaseStructure)`
  height: 100vh;

  animation: ${logoAnimation} 2s ease infinite alternate;

  width: 100%;
  max-width: 100vw;
`;

export const LoadingPage: React.FC = () => {
  return (
    <BackgroundAplat>
      <ShadowedStructure></ShadowedStructure>
      <CenteredLogo src={LogoSimpleT} />
    </BackgroundAplat>
  );
};
