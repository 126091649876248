import React, { useState } from 'react';
import { CardStyle, CardFooter, CardFooterRight } from './DefaultCard';
import { CommentModel } from '../../../models/CommentModel';
import {
  CARDS_TEXT_COLOR,
  CARDS_TITLE_COLOR,
  H_MARGIN,
  DEFAULT_PROFILE_PICTURE_URL,
  TIMELINE_MARGIN,
} from '../../../constants/style';
import styled from 'styled-components';
import {
  FirestoreDateToDate,
  FirestoreDate,
} from '../../../models/FirestoreFields';
import moment from 'moment';
import { TeeWeeUserModel } from '../../../models/TeeWeeUserModel';
import { useUserFromUid } from '../../../controllers/UserHooks';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { PictoSend } from '../../../assets/images/imagesSVG';
import {
  sendNewAnswer,
  deleteAnswerFromComment,
  deleteComment,
} from '../../../controllers/UserController';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ROUTES } from '../../../constants/routes';
import { createChatId } from '../../../utils/ChatsUtils';
import { useCurrentUser } from '../../../utils/AuthUtils';

const padding = '1rem';

const CustomCardStyle = styled(CardStyle)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem 1rem;
`;

const pictureWidthPercent = 22;

const PictureDiv = styled.div`
  padding: 0rem;
  width: ${pictureWidthPercent}%;
  box-sizing: border-box;
`;

const CircleImg = styled.img`
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.08);
  border: solid 5px #ffffff;
  box-sizing: border-box;
`;

const ContentDiv = styled.div`
  padding: 0.5rem 0rem 0rem 0.5rem;
  width: ${100 - pictureWidthPercent}%;

  text-align: left;

  p {
    font-size: 0.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${CARDS_TEXT_COLOR};
  }

  h1 {
    font-size: 0.95rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: ${CARDS_TITLE_COLOR};
    margin-bottom: 0.5rem;
  }
`;

const CardFooterAnswer = styled(CardFooter)`
  max-height: 8vh;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-top: 1rem;

  * {
    box-sizing: border-box;
  }

  ${CircleImg} {
    width: 10%;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  }
`;

const AnswerInput = styled.input`
  border-radius: 4vh;
  border: solid 1px #c6c5c5;
  margin-left: 1rem;
  padding-left: 1rem;
  padding-right: 3rem;
  flex: 1;

  font-family: 'HKNova';
  color: ${CARDS_TEXT_COLOR};

  &::placeholder {
    font-family: 'HKNova';
    color: ${CARDS_TEXT_COLOR};
  }
`;

export const SendButton = styled.button`
  text-decoration: none;
  border: none;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);

  position: absolute;
  align-self: center;
  right: ${padding};
  opacity: 0.5;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 1;
  }

  img {
    filter: grayscale(100%);
  }
`;

const AnswerCardBox = styled.div`
  margin-top: ${TIMELINE_MARGIN};
  margin-left: calc(2 * ${H_MARGIN});
`;

const CommentCardContent: React.FC<{
  content: string | undefined;
  footerDate: FirestoreDate | null;
  user?: TeeWeeUserModel;
  functions?: { [buttonText: string]: (() => void) | null };
  onDelete?: () => void;
}> = ({ user, content, footerDate, functions, onDelete }) => {
  return (
    <>
      <CustomCardStyle>
        <PictureDiv>
          <CircleImg
            src={(user && user.photoURL) || DEFAULT_PROFILE_PICTURE_URL}
            alt={"Profil de l'utilisateur"}
          />
        </PictureDiv>
        <ContentDiv>
          {content ? (
            <>
              <h1>{user ? user.username : 'Anonyme'}</h1>
              <p>{content}</p>
            </>
          ) : (
            <i>Commentaire supprimé</i>
          )}
        </ContentDiv>
      </CustomCardStyle>
      <CardFooter>
        <p>{moment(FirestoreDateToDate(footerDate)).fromNow()}</p>
        <CardFooterRight>
          {content && onDelete && <p onClick={onDelete}>Supprimer</p>}

          {functions &&
            Object.keys(functions).map(buttonText => {
              const onClickFunction = functions[buttonText];
              return (
                onClickFunction !== null && (
                  <p
                    key={buttonText}
                    onClick={onClickFunction}
                    style={{ color: CARDS_TITLE_COLOR }}
                  >
                    {buttonText}
                  </p>
                )
              );
            })}
        </CardFooterRight>
      </CardFooter>
    </>
  );
};

/* Footer shown when user wants to answer to some comment */
const AnswerInputFooter: React.FC<{
  user: TeeWeeUserModel;
  onSend: (answer: string) => void;
}> = ({ user, onSend }) => {
  const [answerValue, setAnswerValue] = useState<string>();

  return (
    <CardFooterAnswer style={{ maxHeight: '10vh' }}>
      <CircleImg
        style={{ maxHeight: '100%', height: '100%' }}
        src={user.photoURL || DEFAULT_PROFILE_PICTURE_URL}
        alt={"Profil de l'utilisateur"}
      />
      <AnswerInput
        type="text"
        placeholder="Répondez..."
        value={answerValue}
        onChange={e => setAnswerValue(e.target.value)}
      ></AnswerInput>
      <SendButton
        type="submit"
        onClick={() => {
          if (!answerValue) {
            return;
          }
          onSend(answerValue);
        }}
      >
        <img src={PictoSend} alt="Send picto" />
      </SendButton>
    </CardFooterAnswer>
  );
};

export const UserCommentCard: React.FC<{
  comment: CommentModel;
  commentId: string;
  isMyPage: boolean;
  pageOwner: TeeWeeUserModel;
  fromUid: string;
}> = ({ comment, commentId, fromUid, isMyPage, pageOwner }) => {
  const user = useUserFromUid(fromUid);

  return (
    <CommentCard
      comment={comment}
      commentId={commentId}
      isMyPage={isMyPage}
      pageOwner={pageOwner}
      commenter={user}
    />
  );
};

export const CommentCard: React.FC<{
  comment: CommentModel;
  commentId: string;
  isMyPage: boolean;
  pageOwner: TeeWeeUserModel;
  commenter?: TeeWeeUserModel | null;
}> = ({ comment, commentId, isMyPage, pageOwner, commenter }) => {
  const firebase = useFirebase();
  const loggedUser = firebase.auth().currentUser;
  const loggedTeeWeeUser = useCurrentUser();
  const firestore = useFirestore();
  const dispatch = useDispatch();

  const [displayAnswerInput, setDisplayAnswerInput] = useState(false);

  return (
    <div
      style={{
        paddingLeft: H_MARGIN,
        paddingRight: H_MARGIN,
      }}
    >
      <CommentCardContent
        user={commenter ? commenter : undefined}
        content={comment.content}
        footerDate={comment.createdAt}
        onDelete={
          isMyPage ||
          loggedUser?.uid === commenter?.uid ||
          loggedTeeWeeUser?.isAdmin
            ? () => deleteComment(pageOwner.uid, commentId, firestore)
            : undefined
        }
        functions={{
          Discuter:
            isMyPage && loggedUser && commenter && !comment.answer
              ? () =>
                  dispatch(
                    push(
                      ROUTES.CHATS + createChatId(loggedUser.uid, commenter.uid)
                    )
                  )
              : null,
          Répondre:
            isMyPage && !comment.answer
              ? () => setDisplayAnswerInput(!displayAnswerInput)
              : null,
        }}
      />
      {isMyPage && displayAnswerInput && loggedUser && (
        <AnswerInputFooter
          user={pageOwner}
          onSend={(answer: string) => {
            sendNewAnswer(answer, loggedUser.uid, commentId, firestore);
            setDisplayAnswerInput(false);
          }}
        />
      )}
      {comment.answer && (
        <AnswerCardBox>
          <CommentCardContent
            user={pageOwner}
            content={comment.answer}
            footerDate={comment.updatedAt}
            onDelete={
              (loggedUser && isMyPage) || loggedTeeWeeUser?.isAdmin
                ? () =>
                    deleteAnswerFromComment(pageOwner.uid, commentId, firestore)
                : undefined
            }
          />
        </AnswerCardBox>
      )}
    </div>
  );
};
