import React from 'react';
import { useDispatch } from 'react-redux';
import {
  SettingsSection,
  MenuList,
  MenuItem,
  MenuItemStyle,
} from './MenuComponents';

import {
  MiniPictoUser,
  MiniPictoShirt,
  MiniPictoSocial,
  MiniPictoCross,
  MiniPictoInfos,
} from '../../../assets/images/imagesSVG';
import { push } from 'connected-react-router';
import { ROUTES } from '../../../constants/routes';

export const MainSettingsSection: React.FC<{}> = () => {
  const dispatch = useDispatch();

  return (
    <SettingsSection title="Informations personnelles">
      <MenuList>
        <MenuItem
          icon={MiniPictoUser}
          onClick={() => dispatch(push(ROUTES.SETTINGS_USER))}
          text={'Réglages du compte'}
        />
        <MenuItem
          icon={MiniPictoShirt}
          onClick={() => dispatch(push(ROUTES.SETTINGS_VESTIAIRE))}
          text={'Mon vestiaire'}
        />
        <MenuItem
          icon={MiniPictoSocial}
          onClick={() => dispatch(push(ROUTES.SETTINGS_SOCIAL))}
          text={'Mes réseaux sociaux'}
        />
        <MenuItem
          icon={MiniPictoInfos}
          onClick={() => dispatch(push(ROUTES.LEGAL))}
          text={'Mentions légales'}
        />
        <MenuItemStyle onClick={() => dispatch(push(ROUTES.SIGN_OUT))}>
          <img src={MiniPictoCross} alt={'Croix'} />
          <div>Se déconnecter</div>
        </MenuItemStyle>
      </MenuList>
    </SettingsSection>
  );
};
