import React from 'react';
import styled from 'styled-components';
import { TeeWeeUserModel } from '../../../models/TeeWeeUserModel';
import { CardStyle } from './DefaultCard';
import {
  CARDS_TEXT_COLOR,
  CARDS_TITLE_COLOR,
  DEFAULT_PROFILE_PICTURE_URL,
} from '../../../constants/style';
import { SocialNetworksNames } from '../../../models/SocialNetworksModel';
import {
  getSocialNetworksURLs,
  SOCIAL_NETWORKS_INFOS,
} from '../../../utils/SocialNetworksUtils';

const UserCardStyle = styled(CardStyle)`
  position: relative;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem 2rem 1rem 1.5rem;
  height: 7.5rem;
`;

const ProfilePictureDiv = styled.div`
  padding: 0;
  width: 35%;
  min-width: 2rem;
  position: relative;

  img {
    border-radius: 50%;
    border: solid 5.5px #ffffff;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;

    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const SocialNetworks = styled.div`
  margin-top: 0.5rem;

  a img {
    height: 1.5rem;
    padding-right: 0.4rem;
  }
`;

const ProfileInfoDiv = styled.div`
  padding: 0.5rem 2rem 0.5rem 1rem;
  width: 80%;

  text-align: left;

  p {
    font-size: 0.9rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${CARDS_TEXT_COLOR};
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 1rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: ${CARDS_TITLE_COLOR};
  }
`;

export const UserCard: React.FC<{
  user: TeeWeeUserModel;
  linkSentence: string;
}> = ({ user, linkSentence }) => {
  const socialNetworks = getSocialNetworksURLs(user.socialNetworks);
  return (
    <UserCardStyle>
      <ProfilePictureDiv>
        <img
          src={user.photoURL || DEFAULT_PROFILE_PICTURE_URL}
          alt={"Image de profil de l'utilisateur " + user.username}
        />
      </ProfilePictureDiv>
      <ProfileInfoDiv>
        <p>{linkSentence}</p>
        <h1>{user.username}</h1>
        <SocialNetworks>
          {Object.entries(socialNetworks)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([keyOriginal, value]) => {
              const key = keyOriginal as SocialNetworksNames;
              return (
                <a href={value} key={key}>
                  <img
                    src={SOCIAL_NETWORKS_INFOS[key].logo}
                    alt={'Logo ' + key}
                  />
                </a>
              );
            })}
        </SocialNetworks>
      </ProfileInfoDiv>
    </UserCardStyle>
  );
};
