import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { StatusModel } from '../../../models/StatusModel';
import { QuoteOpen, QuoteClose } from '../../../assets/images/imagesSVG';
import { FirestoreDateToDate } from '../../../models/FirestoreFields';
import moment from 'moment';
import { CardFooter, CardFooterRight } from './DefaultCard';
import {
  RedShape,
  BlueShape,
  YellowShape,
} from '../../../assets/images/imagesSVG';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { ImagePopUp } from '../modal/ImagePopUp';
import { deleteStatus } from '../../../controllers/UserController';
import { TeeWeeUserModel } from '../../../models/TeeWeeUserModel';
import { useCurrentUser } from '../../../utils/AuthUtils';

export const StatusStyleContainer = styled.div`
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  color: white;
  text-align: center;

  text-shadow: 0 2px 34px rgba(123, 21, 0, 0.46);
  font-size: 1.8rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  & > img {
    width: 100%;
  }
`;

export const StatusStyleContent = styled.div`
  color: white;
  text-align: center;

  text-shadow: 0 2px 34px rgba(123, 21, 0, 0.46);
  font-size: 1.8rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;

  width: 100%;
  height: 100%;

  & > p {
    overflow-wrap: break-word;
    max-height: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Quote = styled.img`
  max-height: 25%;
`;

export const StatusImageStyle = styled.img`
  width: 100%;
`;

//////

export const StatusImage: React.FC<{ path: string }> = ({ path }) => {
  const firebase = useFirebase();
  const storageRef = firebase.storage().ref();

  const [croppedUrl, setCroppedUrl] = useState(RedShape);
  const [fullImageUrl, setFullImageUrl] = useState(RedShape);
  const [displayFullImage, setDisplayFullImage] = useState(false);

  const fullImageRef = storageRef.child(path);
  const croppedImageRef = storageRef.child(path + '_crop');
  croppedImageRef
    .getDownloadURL()
    .then(url => setCroppedUrl(url))
    .catch(e => {
      //Probably still not create
      // eslint-disable-next-line no-undef
      setTimeout(() => {
        croppedImageRef
          .getDownloadURL()
          .then(url => setCroppedUrl(url))
          .catch(e => console.error(e));
      }, 1500);
    });
  fullImageRef
    .getDownloadURL()
    .then(url => setFullImageUrl(url))
    .catch(e => {
      //Probably still not create
      // eslint-disable-next-line no-undef
      setTimeout(() => {
        fullImageRef
          .getDownloadURL()
          .then(url => setFullImageUrl(url))
          .catch(e => console.error(e));
      }, 1500);
    });

  return (
    <>
      {displayFullImage && (
        <ImagePopUp
          imageUrl={fullImageUrl}
          onExit={() => setDisplayFullImage(false)}
        />
      )}
      <StatusImageStyle
        src={croppedUrl}
        onClick={() => setDisplayFullImage(true)}
      />
    </>
  );
};

export const StatusCardContent: React.FC<{ status: StatusModel }> = ({
  status,
}) => {
  const statusTextRef = useRef<HTMLParagraphElement>(null);
  return 'content' in status ? (
    <StatusStyleContainer>
      <img
        src={
          status.color === 'blue'
            ? BlueShape
            : status.color === 'yellow'
            ? YellowShape
            : RedShape
        }
        alt="Fond avec formes"
      />
      <StatusStyleContent>
        <Quote src={QuoteOpen} alt={'Opening quote'} />
        <p
          ref={statusTextRef}
          style={{
            fontSize: !(statusTextRef && statusTextRef.current)
              ? 'auto'
              : statusTextRef.current.clientWidth / 20 + 'px',

            lineHeight: !(statusTextRef && statusTextRef.current)
              ? 'auto'
              : Math.max(
                  statusTextRef.current.clientHeight / 4,
                  statusTextRef.current.clientWidth / 20
                ) + 'px',
          }}
        >
          {status.content}
        </p>
        <Quote src={QuoteClose} alt={'Closing quote'} />
      </StatusStyleContent>
    </StatusStyleContainer>
  ) : 'imagePath' in status ? (
    <StatusImage path={status.imagePath} />
  ) : (
    <StatusStyleContainer>
      <img src={RedShape} alt="FormeRouge" />
      <StatusStyleContent>
        <p style={{ fontStyle: 'italic' }}>Statut supprimé</p>
      </StatusStyleContent>
    </StatusStyleContainer>
  );
};
export const StatusCard: React.FC<{
  status: StatusModel;
  isMyPage: boolean;
  pageOwner: TeeWeeUserModel;
}> = ({ status, isMyPage, pageOwner }) => {
  const firestore = useFirestore();
  const loggedTeeWeeUser = useCurrentUser();
  const isDeleted = !('imagePath' in status) && !('content' in status);

  return (
    <div style={{ marginTop: '2rem' }}>
      <StatusCardContent status={status} />
      <CardFooter style={{ marginBottom: '2rem' }}>
        {moment(FirestoreDateToDate(status.createdAt)).format('LLL')}
        {(isMyPage || loggedTeeWeeUser?.isAdmin) && !isDeleted && (
          <CardFooterRight>
            <p onClick={() => deleteStatus(pageOwner.uid, status, firestore)}>
              Supprimer
            </p>
          </CardFooterRight>
        )}
      </CardFooter>
    </div>
  );
};
