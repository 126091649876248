import { SocialNetworks } from './SocialNetworksModel';

export type TeeWeeUserModel = {
  uid: string;
  visible: boolean;
  allowAnonymousComments: boolean;
  canTalkTo: string[];
  chatsRooms: string[];
  username?: string;
  photoURL?: string;
  currentStatus: string;
  socialNetworks?: SocialNetworks;
  shirts: string[];
  scanned: string[];
  scannedBy: string[];
  isAdmin?: boolean;
};

export const EmptyTeeWeeUser: TeeWeeUserModel = {
  uid: '',
  username: 'Chargement',
  allowAnonymousComments: false,
  canTalkTo: [],
  visible: true,
  currentStatus: 'N/A',
  shirts: [],
  scanned: [],
  scannedBy: [],
  chatsRooms: [],
};
