import React from 'react';
import {
  useFirebase,
  ReduxFirestoreQuerySetting,
  isLoaded,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import { COLLECTIONS } from '../constants/collections';
import UserPage from '../views/pages/user/UserPage';
import { State } from '../redux/reducer';
import { LoadingPage } from '../views/pages/misc/LoadingPage';
import { TeeWeeUserModel } from '../models/TeeWeeUserModel';
import { CommentEntries, StatusEntries } from '../redux/FirestoreData';
import { consolidateTeeWeeUser } from './UserController';

export const useUserFromUid = (uid: string) => {
  const storeDocument: ReduxFirestoreQuerySetting = {
    collection: COLLECTIONS.USERS,
    doc: uid,
  };
  useFirestoreConnect([storeDocument]);

  const currentUser = useSelector((state: State) => {
    const collection = state.firestore.data[COLLECTIONS.USERS] as {
      [id: string]: TeeWeeUserModel;
    };
    return collection && collection[uid];
  });

  if (!currentUser && !isLoaded(currentUser)) {
    return undefined;
  }

  if (!currentUser && isLoaded(currentUser)) {
    return null;
  }

  return consolidateTeeWeeUser(currentUser);
};

export const useUsersFromUids = (uids: string[]) => {
  const storeDocuments: ReduxFirestoreQuerySetting[] = uids.map(uid => {
    return {
      collection: COLLECTIONS.USERS,
      doc: uid,
    };
  });

  useFirestoreConnect(storeDocuments);
  const users = useSelector((state: State) => {
    if (!state.firestore.data.users) {
      return state.firestore.data.users;
    }

    const watchedUsers: { [id: string]: TeeWeeUserModel } = {};
    for (const uidIdx in uids) {
      const collection = state.firestore.data[COLLECTIONS.USERS] as {
        [id: string]: TeeWeeUserModel;
      };
      const uid = uids[uidIdx];
      if (collection[uid]) {
        watchedUsers[uid] = collection[uid];
      }
    }
    return watchedUsers;
  });

  if (!users && !isLoaded(users)) {
    return undefined;
  }

  return users;
};

export const useStatus = (uid: string) => {
  const subCollection = 'userStatusFor' + uid;

  const storeDocument: ReduxFirestoreQuerySetting = {
    collection: COLLECTIONS.USERS,
    doc: uid,
    subcollections: [{ collection: COLLECTIONS.STATUS }],
    storeAs: subCollection,
    orderBy: [['createdAt', 'desc']],
  };
  useFirestoreConnect([storeDocument]);

  const status = useSelector(
    (state: State) =>
      state.firestore.data[subCollection] &&
      (state.firestore.data[subCollection] as StatusEntries)
  );

  if (!isLoaded(status)) {
    return {};
  }

  return status;
};

export const useComments = (uid: string) => {
  const subCollection = 'userCommentsFor' + uid;

  const storeDocument: ReduxFirestoreQuerySetting = {
    collection: COLLECTIONS.USERS,
    doc: uid,
    subcollections: [{ collection: COLLECTIONS.COMMENTS }],
    storeAs: subCollection,
    orderBy: [['createdAt', 'desc']],
  };
  useFirestoreConnect([storeDocument]);

  const comments = useSelector(
    (state: State) =>
      state.firestore.data[subCollection] &&
      (state.firestore.data[subCollection] as CommentEntries)
  );

  if (!isLoaded(comments)) {
    return {};
  }

  return comments;
};

export const SpecificUserControllerFromParams: React.FC<{ uid: string }> = ({
  uid,
}) => {
  const specificUser = useUserFromUid(uid);
  const status = useStatus(uid);
  const comments = useComments(uid);
  const firebase = useFirebase();
  const loggedUser = firebase.auth().currentUser;

  if (!specificUser) {
    return <LoadingPage />;
  }

  // Anonymous user can comment when allowed
  const canAnswerAnonymous = !loggedUser;

  // Logged user can comment if they did not answer the current status
  const canAnswerLogged =
    loggedUser != null &&
    specificUser.uid !== loggedUser.uid &&
    status[specificUser.currentStatus] &&
    !status[specificUser.currentStatus].answeredBy.includes(loggedUser.uid);

  return (
    <UserPage
      user={specificUser}
      status={status}
      comments={comments}
      canAnswer={canAnswerLogged || canAnswerAnonymous}
    />
  );
};

export const CurrentUserController: React.FC = () => {
  const firebase = useFirebase();
  const loggedUser = firebase.auth().currentUser;

  if (!loggedUser) {
    return (
      <p>
        <Link to={ROUTES.LOGIN}>Login</Link> needed
      </p>
    );
  }

  return <SpecificUserControllerFromParams uid={loggedUser.uid} />;
};

export const SpecificUserControllerFromRoute: React.FC<RouteComponentProps<{
  uid: string;
}>> = ({ match }) => {
  return <SpecificUserControllerFromParams uid={match.params.uid} />;
};
