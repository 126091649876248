import React from 'react';
import styled, { keyframes } from 'styled-components';

const particles = 50;
const width = 500;
const height = 500;

let boxInfos: string[] = [];
let boxInfos2: string[] = [];

function random(max: number) {
  return Math.floor(Math.random() * (max + 1));
}

for (let i = 0; i < particles; i++) {
  boxInfos.push(
    `${random(width) - width / 2}px ${random(height) -
      height / 2}px hsl(${random(360)}, 100%, 50%)`
  );
  boxInfos2.push(`0 0 #fff`);
}

const boxShadow = boxInfos.join();
const boxShadow2 = boxInfos2.join();

const BangAnimation = keyframes`
  to {
    box-shadow:${boxShadow};
  }
`;

const GravityAnimation = keyframes`
  to {
    transform(translateY(200px));
    opacity: 0;
  }
`;

const PositionAnimation = keyframes`
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {  
    margin-top: 20%;
    margin-left: 70%
  }
  60%, 79.9% {  
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {  
    margin-top: 30%;
    margin-left: 80%;
  }
`;

const PyroRoot = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  pointer-events: none;
`;

const PyroElements = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: ${boxShadow2};
  animation: 1s ${BangAnimation} ease-out infinite backwards,
    1s ${GravityAnimation} ease-in infinite backwards,
    5s ${PositionAnimation} linear infinite backwards;
`;

const PyroBefore = styled(PyroElements)``;

const PyroAfter = styled(PyroElements)`
  animation-delay: 1.25s, 1.25s, 1.25s;
  animation-duration: 1.25s, 1.25s, 6.25s));
`;

export const FireworksAnimation: React.FC = () => {
  return (
    <PyroRoot>
      <PyroBefore />
      <PyroAfter />
    </PyroRoot>
  );
};
