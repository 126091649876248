import React, { useState } from 'react';
import { SettingsSection } from './MenuComponents';
import * as Forms from '../../components/forms/Form';
import LabelledInput from '../../components/input/LabelledInput';
import ActionButton from '../../components/buttons/ActionButton';
import { useForm } from 'react-hook-form';
import { H_MARGIN } from '../../../constants/style';
import styled from 'styled-components';
import { useCurrentUser } from '../../../utils/AuthUtils';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { updateTeeweeUserModel } from '../../../controllers/UserController';
import { CatchFirebaseFormError } from '../../../constants/errorMessages';
import { SocialNetworks } from '../../../models/SocialNetworksModel';

const FormWithSpace = styled.div`
  label {
    margin-bottom: ${H_MARGIN};
  }
  margin-bottom: ${H_MARGIN};
`;

export const SocialSettingsSection = () => {
  const {
    handleSubmit,
    register: formRef,
    errors,
    clearError,
    setError,
  } = useForm<SocialNetworks>();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const currentFirebaseUser = firebase.auth().currentUser;
  const [infos, setInfos] = useState<string[]>([]);

  const addInfo = (info: string) => {
    setInfos(value => value.concat(info));
  };

  const currentUser = useCurrentUser();

  const onSubmit = (data: SocialNetworks) => {
    if (!currentUser || !currentFirebaseUser) {
      return;
    }
    clearError();
    setInfos([]);

    // TeeWeeModel
    const updateFacebook =
      data.facebook !== currentUser.socialNetworks?.facebook;
    const updateInstagram =
      data.instagram !== currentUser.socialNetworks?.instagram;
    const updateSnapchat =
      data.snapchat !== currentUser.socialNetworks?.snapchat;
    const updateTwitter = data.twitter !== currentUser.socialNetworks?.twitter;
    const updateCustom = data.custom !== currentUser.socialNetworks?.custom;

    const newUser = Object.assign({}, currentUser);
    if (
      updateFacebook ||
      updateInstagram ||
      updateSnapchat ||
      updateTwitter ||
      updateCustom
    ) {
      !data.facebook && data.facebook !== undefined && delete data.facebook;
      !data.instagram && data.instagram !== undefined && delete data.instagram;
      !data.twitter && data.twitter !== undefined && delete data.twitter;
      !data.snapchat && data.snapchat !== undefined && delete data.snapchat;
      !data.custom && data.custom !== undefined && delete data.custom;

      newUser.socialNetworks = data;

      updateTeeweeUserModel(newUser, firestore)
        .then(() => {
          addInfo('Modification effectuée');
        })
        .catch(CatchFirebaseFormError(setError));
    }
  };

  return (
    <SettingsSection title="Mes réseaux sociaux">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWithSpace>
          <Forms.Errors errors={Object.values(errors)} />
          <Forms.Infos infos={infos} />
          <LabelledInput
            innerRef={formRef}
            label="Instagram"
            name="instagram"
            type="text"
            defaultValue={currentUser?.socialNetworks?.instagram}
          />
          <LabelledInput
            innerRef={formRef}
            label="Snapchat"
            name="snapchat"
            type="text"
            defaultValue={currentUser?.socialNetworks?.snapchat}
          />
          <LabelledInput
            innerRef={formRef}
            label="Twitter"
            name="twitter"
            type="text"
            defaultValue={currentUser?.socialNetworks?.twitter}
          />
          <LabelledInput
            innerRef={formRef}
            label="Facebook"
            name="facebook"
            type="text"
            defaultValue={currentUser?.socialNetworks?.facebook}
          />
          <LabelledInput
            innerRef={formRef}
            label="Site Perso"
            name="custom"
            type="text"
            defaultValue={currentUser?.socialNetworks?.custom}
          />
        </FormWithSpace>
        <Forms.Actions>
          <ActionButton
            text="Enregistrer les modifications"
            variant="primary"
            type="submit"
          />
        </Forms.Actions>
      </form>
    </SettingsSection>
  );
};
