import React, { useState } from 'react';
import { BaseStructure } from '../../components/structure/DefaultBackground';
import { BackButtonBar } from '../../components/structure/BackButtonBar';
import {
  BACKGROUND_YELLOW,
  DEFAULT_PROFILE_PICTURE_URL,
  BOTTOM_ROUNDED_BACKGROUND,
} from '../../../constants/style';
import styled from 'styled-components';
import { ROUTES } from '../../../constants/routes';
import { useUserFromUid } from '../../../controllers/UserHooks';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ProfileInfos,
  ProfilePictureSection,
  ProfilePicture,
  ProfilePictureButton,
} from './MenuComponents';
import { MainSettingsSection } from './MainSettingsSection';
import { AccountSettingsSection } from './AccountSettingsSection';
import { CropPopUp } from '../../components/modal/CropPopUp';
import { ImageInput } from '../../components/misc/ImageInput';
import { uploadProfilePicture } from '../../../controllers/UserController';
import { SocialSettingsSection } from './SocialSettingsSection';
import { VestiaireSettingsSection } from './VestiaireSettingsPage';
import { LoadingPage } from '../misc/LoadingPage';

const FullPageStructure = styled(BaseStructure)`
  background-color: ${BOTTOM_ROUNDED_BACKGROUND};
  min-height: 100vh;
`;

const SettingWithUid: React.FC<{ uid: string }> = ({ uid }) => {
  const user = useUserFromUid(uid);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const userFirebase = firebase.auth().currentUser;

  // Profile picture

  const [fullImageUrl, setFullImageUrl] = useState('');
  const [displayCropPopup, setDisplayCropPopup] = useState(false);

  if (!user && user === null) {
    return <Redirect to="/" />;
  }
  if (!user) {
    return <LoadingPage />;
  }

  return (
    <FullPageStructure>
      {displayCropPopup && (
        <CropPopUp
          imageUrl={fullImageUrl}
          aspect={1}
          onExit={() => {
            setFullImageUrl('');
            setDisplayCropPopup(false);
          }}
          onComplete={newProfileImageUrl => {
            setDisplayCropPopup(false);
            uploadProfilePicture(
              newProfileImageUrl,
              userFirebase,
              firebase,
              firestore
            );
          }}
        />
      )}

      <div style={{ backgroundColor: BACKGROUND_YELLOW, paddingBottom: '6vh' }}>
        <BackButtonBar />

        <ProfileInfos>
          <ProfilePictureSection>
            <ProfilePicture
              src={user.photoURL || DEFAULT_PROFILE_PICTURE_URL}
              alt={"Image de profil de l'utilisateur " + user.username}
            />
            <ProfilePictureButton>
              <label>
                <ImageInput
                  alt={'Edit'}
                  onSelect={imageUrl => {
                    if (imageUrl === null) {
                      setFullImageUrl('');
                    } else {
                      setFullImageUrl(imageUrl);
                      setDisplayCropPopup(true);
                    }
                  }}
                />
              </label>
            </ProfilePictureButton>
          </ProfilePictureSection>
          <h1>{user?.username}</h1>
        </ProfileInfos>
      </div>
      <Switch>
        <Route path={ROUTES.SETTINGS_USER}>
          <AccountSettingsSection />
        </Route>
        <Route path={ROUTES.SETTINGS_VESTIAIRE}>
          <VestiaireSettingsSection />
        </Route>
        <Route path={ROUTES.SETTINGS_SOCIAL}>
          <SocialSettingsSection />
        </Route>
        <Route path={ROUTES.SETTINGS_MAIN}>
          <MainSettingsSection />
        </Route>
      </Switch>
    </FullPageStructure>
  );
};

const SettingsPage: React.FC = () => {
  const firebase = useFirebase();
  const loggedUser = firebase.auth().currentUser;

  if (!loggedUser) {
    return <Redirect to="/" />;
  } else {
    return <SettingWithUid uid={loggedUser.uid} />;
  }
};

export default SettingsPage;
