import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants/collections';
import { ChatMemberInfos } from '../../models/chat/ChatMemberInfos';
import { TeeWeeUserModel } from '../../models/TeeWeeUserModel';
import { State } from '../../redux/reducer';
import { useChatsRooms } from './ChatRoomHooks';

export const useRoomMemberInfos = (uid: string, roomsIds: string[]) => {
  const storeDocuments: ReduxFirestoreQuerySetting[] = roomsIds.map(roomId => {
    const subCollection = 'memberInfosForRoom' + roomId;
    return {
      collection: COLLECTIONS.CHAT_ROOMS,
      doc: roomId,
      subcollections: [
        { collection: COLLECTIONS.CHAT_MEMBERS_INFOS, doc: uid },
      ],
      storeAs: subCollection,
    };
  });
  useFirestoreConnect(storeDocuments);

  const memberInfos = useSelector((state: State) => {
    const watchedRooms: { [id: string]: ChatMemberInfos } = {};
    for (const idIdx in roomsIds) {
      const roomId = roomsIds[idIdx];
      const subCollection = 'memberInfosForRoom' + roomId;

      const element = state.firestore.data[subCollection] as ChatMemberInfos;
      if (element) {
        watchedRooms[roomId] = element;
      }
    }

    return watchedRooms;
  });

  if (!memberInfos && !isLoaded(memberInfos)) {
    return undefined;
  }

  return memberInfos;
};

export const useOneRoomMemberInfos = (uid: string, roomId: string) => {
  const infos = useRoomMemberInfos(uid, [roomId]);
  return infos ? infos[roomId] : infos;
};

export const useHasChatsNotifications = (user: TeeWeeUserModel) => {
  const rooms = useChatsRooms(user.chatsRooms);
  const infos = useRoomMemberInfos(user.uid, user.chatsRooms);

  if (!rooms || !infos) {
    return undefined;
  }

  let hasNotifications = false;
  for (const idx in user.chatsRooms) {
    const roomId = user.chatsRooms[idx];
    const chatRoom = rooms[roomId];
    const memberInfo = infos[roomId];

    hasNotifications = !(
      chatRoom &&
      memberInfo &&
      chatRoom.lastMessage &&
      chatRoom.lastMessage.sentAt
    )
      ? false
      : !memberInfo.lastReadAt
      ? true
      : memberInfo.lastReadAt.seconds < chatRoom.lastMessage.sentAt.seconds;

    if (hasNotifications) {
      break;
    }
  }
  /**/
  return hasNotifications;
};
