import React from 'react';
import { ROUTES } from '../../../constants/routes';
import { Link } from 'react-router-dom';
import ActionButton from '../../components/buttons/ActionButton';

import AllSteps from '../../../assets/images/onboarding/allsteps.png';
import { OnboardingElements } from '../../components/slider/OnboardingSlider';
import { HeaderBar } from '../../components/structure/HeaderBar';
import styled from 'styled-components';
import { Subtitle, Title } from '../../components/forms/Form';

export const DesktopStructure = styled.div`
  background-color: #f0f0f0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const AllStepsContainer = styled.div`
  height: 50vh;
  width: 100%;
  flex: none;
  background: white;
  display: block;

  img {
    display: block;
    margin: auto;
    max-height: 100%;
  }
`;

const OnboardingElementsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin: 0 5%;
`;

const OnboardingElement = styled.div`
  flex-grow: 1;
  margin: 0 5rem;

  ${Title} {
    text-align: center;
  }

  ${Subtitle} {
    color: #777;
    text-align: justify;
  }
`;

const FooterButtons = styled.div`
  margin-left: 25%;
  margin-right: 25%;
  display: flex;
  flex-direction: row;

  a {
    flex-grow: 1;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

const Footer = styled.div`
  flex: none;
  text-align: center;
  margin-bottom: 3rem;
`;

const DesktopWelcomePage: React.FC = () => {
  return (
    <DesktopStructure>
      <HeaderBar />
      <AllStepsContainer>
        <img src={AllSteps} alt="Etapes TeeWee" />
      </AllStepsContainer>
      <OnboardingElementsContainer>
        {OnboardingElements.map(element => (
          <OnboardingElement key={element.title}>
            <div>
              <Title>{element.title}</Title>
              <Subtitle>{element.subtitle}</Subtitle>
            </div>
          </OnboardingElement>
        ))}
      </OnboardingElementsContainer>
      <Footer>
        <FooterButtons>
          <Link to={ROUTES.LOGIN}>
            <ActionButton text="Se connecter" variant="primary" type="reset" />
          </Link>
          <Link to={ROUTES.REGISTER}>
            <ActionButton text="S'inscrire" variant="secondary" type="reset" />
          </Link>
        </FooterButtons>
        <FooterButtons>
          <Link to={ROUTES.STORE}>
            <ActionButton
              text="Acheter nos teeshirts 100% bio"
              variant="danger"
            />
          </Link>
        </FooterButtons>
        <Link to={ROUTES.LEGAL}>
          <i>Mentions légales</i>
        </Link>
      </Footer>
    </DesktopStructure>
  );
};

export default DesktopWelcomePage;
