import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  user-select: none;
  color: #37373b;
  opacity: 0.7;
  font-family: HKNova;
  font-size: 1rem;
`;

const StyledCheckBoxInput = styled.input`
  margin-right: 0.5rem;
  box-sizing: content-box;

  width: 1rem;
  height: 1rem;
  background-color: white;
  border-radius: 33%;
  vertical-align: middle;
  border: 2px solid #37373b;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: #0056d1;
  }
`;

interface CheckBoxInputProps {
  text: string;
  name: string;
  defaultChecked?: boolean;
  innerRef: () => void;
}

const CheckBoxInput: React.FC<CheckBoxInputProps> = props => (
  <Label>
    <StyledCheckBoxInput type="checkbox" ref={props.innerRef} {...props} />
    {props.text}
  </Label>
);

export default CheckBoxInput;
