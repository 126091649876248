import React from 'react';
import styled from 'styled-components';
import { ButtonCard, ButtonIcon } from '../buttons/ButtonCard';
import { PictoCross } from '../../../assets/images/imagesSVG';

const ImagePopupContainer = styled.div`
  background-color: #000000de;
  height: 100vh;
  max-height: 100vh;
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  margin: auto;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 100;

  ${ButtonCard} {
    position: fixed;
    top: 1rem;
    right: 1rem;
    margin: 0;
  }
`;

export const ImagePopUp: React.FC<{
  imageUrl: string;
  onExit: () => void;
}> = ({ imageUrl, onExit }) => {
  return (
    <ImagePopupContainer
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <img
        src={imageUrl}
        alt="Zoom"
        style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }}
      />

      <ButtonCard type={'button'} onClick={onExit}>
        <ButtonIcon src={PictoCross} />
      </ButtonCard>
    </ImagePopupContainer>
  );
};
