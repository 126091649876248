import styled from 'styled-components';

const BaseButton = styled.button`
  border: 0px;
  cursor: pointer;
  margin-bottom: 1em;
  font-family: HKNova;
  width: 100%;

  &::-moz-focus-inner {
    border: 0;
    outline: 0;
  }
  &:focus {
    border: 0;
    outline: 0;
  }
  background-color: transparent;
  font-size: 1em;
`;

export default BaseButton;
