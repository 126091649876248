import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { History } from 'history';
/*eslint-disable */
import logger from 'redux-logger';
/*eslint-enable */
import thunk from 'redux-thunk';
import rootReducer, { State } from './reducer';
import { saveState } from './localStorage';

export default function configureStore(initialState: object, history: History) {
  let parameters = {};
  let middlewares = [routerMiddleware(history), thunk];
  if (process.env.NODE_ENV !== 'production') {
    //middlewares = [...middlewares, logger];
    parameters = {
      trace: true,
      traceLimit: 50,
    };
  }

  const composeEnhancers = composeWithDevTools(parameters);

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  store.subscribe(() => saveState(store.getState() as State));

  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept('./reducer', () => {
      const nextRootReducer = rootReducer(history);
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
