import styled, { css } from 'styled-components';
import { CARDS_TEXT_COLOR } from '../../../constants/style';
import BaseButton from '../buttons/BaseButton';

const CardCss = css`
  background-color: white;
  min-height: 3rem;
  color: #262628;

  border-radius: 17px;
  padding: 2em 0.5em;
  margin-bottom: 0.2rem;
  text-align: center;

  box-sizing: border-box;
`;

const ShadowedCss = css`
  box-shadow: 0 4px 66px 0 rgba(0, 0, 0, 0.15);
  color: ${CARDS_TEXT_COLOR};
  margin-bottom: 0.5rem;
`;

export const CardStyle = styled.div`
  ${CardCss}
`;

export const ShadowedCard = styled(CardStyle)`
  ${ShadowedCss}
`;

export const CardStyleButton = styled(BaseButton)`
  ${CardCss}
`;

export const ShadowedCardButton = styled(CardStyleButton)`
  ${ShadowedCss};
`;

export const CardFooter = styled.div`
  color: ${CARDS_TEXT_COLOR};
  font-size: 0.75rem;
  padding-top: 0.5rem;
  position: relative;

  p {
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const CardFooterRight = styled.div`
  position: absolute;
  right: 0;
  display: inline-block;

  p {
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;
  }

  p:hover {
    color: red;
  }
`;
