import React from 'react';
import styled from 'styled-components';
import { MAX_WIDTH } from '../../../constants/style';

export const BaseStructure = styled.div`
  background-color: #e7e7e7;
  max-width: ${MAX_WIDTH};
  margin: auto;
  min-height: 100vh;
`;

interface DefaultBackgroundProps {
  style?: React.CSSProperties;
}

export const DefaultBackground: React.FC<DefaultBackgroundProps> = ({
  children,
  style,
}) => {
  return <BaseStructure style={style}>{children}</BaseStructure>;
};

export const FlexStructure = styled(BaseStructure)`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  position: relative;
`;

export const PageContainer = styled.div`
  position: relative;
  flex-grow: 1;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
