import React from 'react';
import ShapedBackground from '../../components/structure/ShapedBackground';
import ResetForm from '../../components/forms/ResetForm';

const ResetPage: React.FC = () => {
  return (
    <ShapedBackground>
      <ResetForm />
    </ShapedBackground>
  );
};

export default ResetPage;
