import React from 'react';
import styled from 'styled-components';

const SectionTitle = styled.h2`
  margin-top: 1em;
  margin-bottom: 1em;
  text-transform: uppercase;
  font-size: 0.8em;
  color: #0056d1;
  letter-spacing: 0.0575em;
  display: flex;
  font-weight: bold;
  flex-direction: column;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`;

const FormSection: React.FC<{}> = ({ children }) => {
  return <SectionTitle>{children}</SectionTitle>;
};
export default FormSection;
