export const ROUTES = {
  ROOT: '/',

  // USER ROUTES :
  LOGIN: '/login',
  REGISTER: '/register',
  SIGN_OUT: '/signout',
  RESET: '/reset',

  LEGAL: '/legal',
  CONCEPT: '/concept',

  SETTINGS: '/settings/',
  SETTINGS_MAIN: '/settings/',
  SETTINGS_USER: '/settings/user',
  SETTINGS_SOCIAL: '/settings/social',
  SETTINGS_VESTIAIRE: '/settings/vestiaire',

  USERS: '/users/',

  CHATS: '/chats/',

  SHIRTS: '/t/',

  STORE: '/store',
  STORE_CART: '/store/cart',
  STORE_SUCCESS: '/store/success',
};
