import React from 'react';

import InputLabel from './InputLabel';
import BaseInput from './BaseInput';

interface LabelledInputProps {
  type: string;
  label: string;
  name: string;
  required?: boolean;
  defaultValue?: string;
  innerRef: (() => void) | React.RefObject<HTMLInputElement>;
  style?: React.CSSProperties;
}

const LabelledInput: React.FC<LabelledInputProps> = props => {
  return (
    <InputLabel text={props.label} style={props.style}>
      <br />
      <BaseInput {...props} />
    </InputLabel>
  );
};

export default LabelledInput;
