import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import styled from 'styled-components';

import Step1 from '../../../assets/images/onboarding/step-1.png';
import Step2 from '../../../assets/images/onboarding/step-2.png';
import Step3 from '../../../assets/images/onboarding/step-3.png';
import SliderStepper from './SliderStepper';
import { Title, Subtitle } from '../forms/Form';
import ActionButton from '../buttons/ActionButton';
import { ROUTES } from '../../../constants/routes';
import { SliderElement } from './SliderElement';

const ImageSlide = styled.img`
  width: 100%;
  background-color: white;
`;

const PaddedDiv = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;

  & > a > i {
    opacity: 0.9;
    font-size: 0.75rem;
    text-align: center;
    display: block;
  }
`;

const subtitleStyle = { lineHeight: '1.4rem', marginBottom: '35px' };
const titleStyle = { paddingTop: '0', marginTop: '2rem' };

export const OnboardingElements: SliderElement[] = [
  {
    title: '1. Repère des Teewistes !',
    img: Step1,
    subtitle: `Sois attentif aux gens, n'importe où, dans la rue, en festival, en soirée, ouvre l'œil et trouve des porteurs de T-Shirts Teewee ;)`,
  },
  {
    title: '2. Flash leur QR',
    img: Step2,
    subtitle: `Dégaine ton téléphone pour flasher leur QR code. C'est l'unique manière d'ajouter une personne sur ton réseau Teewee.`,
  },
  {
    title: '3. Échange avec eux',
    img: Step3,
    subtitle: `Bravo ! Tu as maintenant accès aux publications du teewiste ! Tu peux même lui laisser un message pour entrer en contact ;)`,
  },
];

export default function OnboardingSlider(): ReactElement {
  const [currentIndex, setCurrentIndex] = useState(0);
  const settings = {
    arrows: false,
    // eslint-disable-next-line react/display-name
    appendDots: function(dots: React.ReactNode) {
      return (
        <PaddedDiv>
          <SliderStepper
            currentStep={currentIndex}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            stepCount={dots && (dots as any).length ? (dots as any).length : 0}
          />
        </PaddedDiv>
      );
    },
    afterChange: (current: number) => setCurrentIndex(current),
    dots: true,
    dotsClass: 'slick-dots slick-thumb custom-slick-dots',
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
  };

  return (
    <>
      <Slider {...settings}>
        {OnboardingElements.map(element => (
          <div key={element.title}>
            <ImageSlide src={element.img} />
            <PaddedDiv>
              <Title style={titleStyle}>{element.title}</Title>
              <Subtitle style={subtitleStyle}>{element.subtitle}</Subtitle>
            </PaddedDiv>
          </div>
        ))}

        <div>
          <PaddedDiv>
            <Title style={{ ...titleStyle, textAlign: 'center' }}>
              Rejoins l'expérience !
            </Title>
            <Link to={ROUTES.LOGIN}>
              <ActionButton
                text="Se connecter"
                variant="primary"
                type="reset"
              />
            </Link>
            <Link to={ROUTES.REGISTER}>
              <ActionButton
                text="S'inscrire"
                variant="secondary"
                type="reset"
              />
            </Link>
            <Link to={ROUTES.LEGAL}>
              <i>Mentions légales</i>
            </Link>
          </PaddedDiv>
        </div>
      </Slider>
    </>
  );
}
