import styled from 'styled-components';
import { H_MARGIN } from '../../../../constants/style';

//////
export const ProductContainerStyle = styled.div`
  padding: 2rem ${H_MARGIN};
  background-color: white;
`;
export const ProductName = styled.h1`
  font-weight: 800;
  font-size: 1.5rem;
  color: #0063dc;
  margin: 1rem 0rem;
`;
export const Price = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  color: #fa3b15;
`;
export const Description = styled.p`
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1rem;
  color: #afafaf;
`;
export const SubTitle = styled.h2`
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  margin-top: 1.5rem;
  color: #0063dc;
`;
export const ChoiceButton = styled.button`
  cursor: pointer;
  font-size: 0.9rem;

  font-family: HKNova;
  color: #0063dc;

  padding: 0.5rem;
  margin-right: 0.5rem;
  min-width: 2.2rem;

  border-radius: 10px;
  border: solid 1px #d6d6d6;

  background-color: white;
  &:hover {
    background-color: #eee;
  }

  &:active {
    border-color: #0056d1;
  }
`;
export const ChoiceButtonColor = styled.button`
  cursor: pointer;

  margin-right: 0.5rem;

  min-height: 2.2rem;
  min-width: 2.2rem;
  border-radius: 10px;
  border: solid 1px #d6d6d6;

  background-color: white;
  &:hover {
    opacity: 0.9;
  }

  &:active {
    border-color: #969696;
  }
`;

////
export const ImageSlide = styled.img`
  width: 100%;
  height: auto;
  background-color: #e7e7e7;
`;

export const PaddedDiv = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  & > a > i {
    opacity: 0.9;
    font-size: 0.75rem;
    text-align: center;
    display: block;
  }
`;
