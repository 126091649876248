import React from 'react';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';

import ActionButton from '../buttons/ActionButton';
import LabelledInput from '../input/LabelledInput';
import * as Forms from './Form';
import { CatchFirebaseFormError } from '../../../constants/errorMessages';
import { State } from '../../../redux/reducer';
import { removeLoginRedirectionUrl } from '../../../redux/reducers/LoginRedirectionReducer';
import { updateUsername } from '../../../controllers/UserController';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';

interface FormData {
  username: string;
  email: string;
  password: string;
}

const RegisterForm: React.FC = () => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { handleSubmit, register, errors, clearError, setError } = useForm<
    FormData
  >();
  const dispatch = useDispatch();

  const redirectionURL = useSelector((state: State) =>
    state.loginRedirection ? state.loginRedirection : '/'
  );

  const onSubmit = ({ email, password, username }: FormData) => {
    clearError();
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(credentials => {
        if (!credentials.user) {
          setError(
            'Credentials Error',
            'CREDENTIALS',
            "Erreur à la création du nom d'utilisateur"
          );
        } else {
          updateUsername(username, credentials.user?.uid, firestore)
            .then(() => {
              dispatch(removeLoginRedirectionUrl());
              dispatch(push(redirectionURL));
            })
            .catch(CatchFirebaseFormError(setError, 'Error in UpdateUsername'));
        }
      })
      .catch(CatchFirebaseFormError(setError, 'Error in Create'));
  };

  return (
    <Forms.Container onSubmit={handleSubmit(onSubmit)}>
      <Forms.Content>
        <Forms.Title>{`S'inscrire`}</Forms.Title>
        <Forms.Subtitle>{`Débute l'aventure Teewee !`}</Forms.Subtitle>
        <Forms.Errors errors={Object.values(errors)} />
        <LabelledInput
          innerRef={register}
          label="Nom d'utilisateur"
          name="username"
          type="text"
          required
        />
        <LabelledInput
          innerRef={register}
          label="Email"
          name="email"
          type="email"
          required
        />
        <LabelledInput
          innerRef={register}
          label="Mot de passe"
          type="password"
          name="password"
          required
        />
      </Forms.Content>
      <Forms.Actions>
        <i>
          En m'inscrivant à TeeWee, je reconnais avoir lu et accepter les{' '}
          <Link to={ROUTES.LEGAL}>conditions d&apos;utilisation</Link> du site
        </i>
        <ActionButton text="S'inscrire" variant="primary" type="submit" />
      </Forms.Actions>
    </Forms.Container>
  );
};

export default RegisterForm;
