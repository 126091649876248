import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants/collections';
import { ChatMessageModel } from '../../models/chat/ChatMessageModel';
import { State } from '../../redux/reducer';

export const useChatMessages = (
  roomId: string,
  limit?: number,
  startAfter?: string
) => {
  const subCollection = 'chatsMessagesForRoom' + roomId;
  const storeDocument: ReduxFirestoreQuerySetting = {
    collection: COLLECTIONS.CHAT_ROOMS,
    doc: roomId,
    subcollections: [{ collection: COLLECTIONS.CHAT_MESSAGES }],
    storeAs: subCollection,
    orderBy: [['sentAt', 'desc']],
    startAfter: startAfter,
    limit: limit,
  };
  useFirestoreConnect([storeDocument]);

  const chatMessages: { [id: string]: ChatMessageModel } = useSelector(
    (state: State) =>
      state.firestore.data[subCollection] &&
      (state.firestore.data[subCollection] as {
        [id: string]: ChatMessageModel;
      })
  );

  if (!chatMessages && !isLoaded(chatMessages)) {
    return undefined;
  }

  if (!chatMessages && isLoaded(chatMessages)) {
    return [];
  }

  return chatMessages;
};
