import {
  ReduxFirestoreQuerySetting,
  isLoaded,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../constants/collections';
import { State } from '../redux/reducer';
import { ProductModel } from '../models/store/ProductModel';

export const useAllProducts = () => {
  const storeDocuments: ReduxFirestoreQuerySetting = {
    collection: COLLECTIONS.PRODUCTS,
    orderBy: [['modelName', 'desc']],
  };

  useFirestoreConnect(storeDocuments);

  const products = useSelector((state: State) => {
    const collection = state.firestore.data[COLLECTIONS.PRODUCTS];
    if (collection === undefined) {
      return undefined;
    }
    if (collection === null) {
      return null;
    }

    return collection as {
      [id: string]: ProductModel;
    };
  });

  if (!products && !isLoaded(products)) {
    return undefined;
  }

  return products;
};

export const useCart = () => {
  const cart = useSelector((state: State) => state.cart);
  return cart;
};
