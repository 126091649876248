import React from 'react';
import { useHistory } from 'react-router';
import LeftArrow from '../../../assets/images/pictos/arrow.svg';
import { ButtonCard, ButtonIcon } from './ButtonCard';

interface BackButton {
  onClick?: () => void;
}



const BackButton: React.FC<BackButton> = () => {
  const history = useHistory();
  return (
    <ButtonCard type={'button'} onClick={history.goBack}>
      <ButtonIcon src={LeftArrow} />
    </ButtonCard>
  );
};

export default BackButton;
