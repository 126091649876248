import React from 'react'
import styled from 'styled-components'

import SliderDot from './SliderDot'

const getWrapper = (stepCount: number) => styled.div`
    width: ${stepCount * 1.5}rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

interface Props {
    stepCount: number;
    currentStep: number;
}

const SliderStepper = ({ stepCount, currentStep }: Props) => {
    const Wrapper = getWrapper(stepCount);
    const dots = [];
    for (let i = 0; i < stepCount; i++) {
        dots.push(<SliderDot selected={i === currentStep} />)
    }
    return (
        <Wrapper>
            {dots}
        </Wrapper>
    );
}

export default SliderStepper;
