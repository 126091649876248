import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import BaseButton from './BaseButton';
import {
  BUTTON_PRIMARY_COLOR,
  BUTTON_PRIMARY_COLOR_ACTIVE,
  BUTTON_SECONDARY_COLOR,
  BUTTON_SECONDARY_COLOR_ACTIVE,
  BUTTON_PRIMARY_COLOR_TEXT,
  BUTTON_SECONDARY_COLOR_TEXT,
} from '../../../constants/style';

const StyledActionButton = styled(BaseButton)`
  border-radius: 0.8em;

  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledText = styled.div`
  margin: 1.2em;
`;

const PrimaryButton = styled(StyledActionButton)`
  background-color: ${BUTTON_PRIMARY_COLOR};
  &:active {
    background-color: ${BUTTON_PRIMARY_COLOR_ACTIVE};
  }
  font-size: 1em;
  color: ${BUTTON_PRIMARY_COLOR_TEXT};

  &:disabled {
    background-color: rgba(0, 86, 209, 0.75);
  }
`;

const SecondaryButton = styled(StyledActionButton)`
  background-color: ${BUTTON_SECONDARY_COLOR};
  font-size: 1em;
  &:active {
    background-color: ${BUTTON_SECONDARY_COLOR_ACTIVE};
  }
  color: ${BUTTON_SECONDARY_COLOR_TEXT};
`;

const DangerButton = styled(StyledActionButton)`
  background-color: #fa3b15;
  color: white;
  font-size: 1em;
  &:active {
    background-color: rgba(250, 59, 21, 0.9);
  }

  &:disabled {
    background-color: rgba(250, 59, 21, 0.75);
  }
`;

interface ActionButtonProps {
  text: string;
  variant: 'primary' | 'secondary' | 'danger';
  onClick?: (() => void) | ((e: MouseEvent<HTMLButtonElement>) => void);
  type?: 'button' | 'submit' | 'reset';
  style?: React.CSSProperties;
  role?: string;
  disabled?: boolean;
}

const StyledButtons = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  danger: DangerButton,
};

const ActionButton: React.FC<ActionButtonProps> = ({
  type,
  variant,
  text,
  onClick: handleOnClick,
  style,
  role,
  disabled,
}) => {
  const StyledButton = StyledButtons[variant];
  return (
    <StyledButton
      onClick={handleOnClick}
      type={type}
      style={style}
      role={role}
      disabled={disabled}
    >
      <StyledText>{text}</StyledText>
    </StyledButton>
  );
};

export default ActionButton;
