import { State } from './reducer';
import { CartItemModel } from '../models/store/CartModel';

interface SubState {
  cart?: CartItemModel[];
}
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState) as SubState;
  } catch (err) {
    return {};
  }
};

export const saveState = (state: State) => {
  //We only save some keys
  const subState = {
    cart: state.cart,
  };
  try {
    const serializedState = JSON.stringify(subState);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};
