import React from 'react';
import styled from 'styled-components';

const Dot = styled.div`
    width: 0.4rem;
    height: 0.4rem;
    background-color: #f9d17c;
    border-radius: 0.4rem;
`;

const SelectedDot = styled(Dot)`
    width: 1.6rem;
    background-color: #fdb022;
`

interface Props {
    selected?: boolean;
}

const SliderDot = ({ selected }: Props) => {
    return selected ? <SelectedDot /> : <Dot />
}

export default SliderDot
