import firebase from 'firebase';
import { ExtendedFirestoreInstance } from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants/collections';
import { ChatRoomModel } from '../../models/chat/ChatRoomModel';
import { explodeMembersFromChatId } from '../../utils/ChatsUtils';

export const createChat = (
  roomId: string,
  firestore: ExtendedFirestoreInstance
) => {
  const members = explodeMembersFromChatId(roomId);
  const newRoom: ChatRoomModel = {
    roomId: roomId,
    members: members,
    messagesIds: [],
  };

  return firestore
    .collection(COLLECTIONS.CHAT_ROOMS)
    .doc(roomId)
    .set(newRoom);
};

export const sendChatMessage = (
  chatId: string,
  fromUid: string,
  content: string,
  firestore: ExtendedFirestoreInstance
) => {
  const newValue = {
    roomId: chatId,
    fromUid: fromUid,
    content: content,
    sentAt: firebase.firestore.FieldValue.serverTimestamp(),
  };

  return firestore
    .collection(COLLECTIONS.CHAT_ROOMS)
    .doc(chatId)
    .collection(COLLECTIONS.CHAT_MESSAGES)
    .add(newValue);
};

export const updateRoomMemberInfos = (
  uid: string,
  chatId: string,
  firestore: ExtendedFirestoreInstance
) => {
  const newValue = {
    lastReadAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  return firestore
    .collection(COLLECTIONS.CHAT_ROOMS)
    .doc(chatId)
    .collection(COLLECTIONS.CHAT_MEMBERS_INFOS)
    .doc(uid)
    .update(newValue);
};
