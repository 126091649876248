import { Area } from 'react-easy-crop/types';
import { Orientation } from 'get-orientation/browser';
import { Reference } from '@firebase/storage-types';

export const OrientationToAngle = (orientation: Orientation) => {
  switch (orientation) {
    case Orientation.TOP_LEFT:
    case Orientation.TOP_RIGHT:
      return 0;
    case Orientation.BOTTOM_RIGHT:
    case Orientation.BOTTOM_LEFT:
      return 180;
    case Orientation.LEFT_TOP:
    case Orientation.RIGHT_TOP:
      return 90;
    case Orientation.RIGHT_BOTTOM:
    case Orientation.LEFT_BOTTOM:
      return -90;
  }
};

export const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => {
      //TODO Load event does not trigger on firefox android when camera is selected
      console.log('LOADED');
      resolve(image);
    });
    image.addEventListener('error', error => {
      reject(error);
    });
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export async function getRotatedImage(imageSrc: string, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;

  const orientationChanged =
    rotation === 90 ||
    rotation === -90 ||
    rotation === 270 ||
    rotation === -270;
  if (orientationChanged) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);

  return new Promise<string>(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}

/////

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(1, 1, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise<string>(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}

export async function uploadImageFromString(
  targetRef: Reference,
  imageUrl: string
) {
  console.log('Uploading ', imageUrl);
  if (imageUrl.startsWith('blob')) {
    const blob = await fetch(imageUrl).then(r => r.blob());
    return targetRef.put(blob);
  } else {
    //Base64
    const [imageType, imageWithoutMetadata] = imageUrl
      .split(':', 2)[1]
      .split(';', 2);

    const fullImageBase64 = imageWithoutMetadata.split(',', 2)[1];

    return targetRef.putString(fullImageBase64, 'base64', {
      contentType: imageType,
    });
  }
}
