import React from 'react';
import {
  FlexStructure,
  PageContainer,
} from '../../components/structure/DefaultBackground';
import { HeaderBar } from '../../components/structure/HeaderBar';
import styled from 'styled-components';
import {
  H_MARGIN,
  H_MARGIN_VALUE,
  H_MARGIN_UNIT,
  DEFAULT_PROFILE_PICTURE_URL,
} from '../../../constants/style';
import { PictoGear } from '../../../assets/images/imagesSVG';
import { ROUTES } from '../../../constants/routes';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { BottomTabBar } from '../../components/structure/BottomTabBar';
import { useCurrentUser } from '../../../utils/AuthUtils';
import { useOneRoomMemberInfos } from '../../../controllers/chats/ChatsHooks';
import { useChatsRooms } from '../../../controllers/chats/ChatRoomHooks';
import { ChatRoomModel } from '../../../models/chat/ChatRoomModel';
import { TeeWeeUserModel } from '../../../models/TeeWeeUserModel';
import { useUserFromUid } from '../../../controllers/UserHooks';
import NotificationIndicator from '../../components/misc/NotificationIndicator';

// Images
const MarginBody = styled.div`
  padding: 0 ${2 * H_MARGIN_VALUE + H_MARGIN_UNIT};
  padding-top: ${H_MARGIN};
`;

//////

const ChatRoomProfile = styled.img`
  display: block;
  box-sizing: border-box;

  border-radius: 50%;
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.08);
  border: solid 5px #ffffff;

  flex: none;
  width: 4rem;
  height: 4rem;
`;

const ChatRoomContent = styled.div`
  flex-grow: 1;
  align-self: center;
  padding: 0 1rem;

  display: flex;
  flex-direction: column;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;

  h1 {
    opacity: 0.8;
    color: #313134;
    margin-bottom: 0.5rem;
  }

  p {
    width: 100%;

    color: #afafaf;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    line-height: 1.1rem;

    i {
      font-style: italic;
    }
  }
`;

const ChatRoomContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;

    ${ChatRoomProfile} {
      border-width: 3px;
    }

    ${ChatRoomContent} {
      h1 {
        opacity: 1;
        color: #212124;
      }

      p {
        color: #262628;
      }
    }
  }
`;

const ChatRoomContainerActive = styled(ChatRoomContainer)`
  ${ChatRoomContent} {
    h1 {
      opacity: 1;
      margin-bottom: 0.5rem;
    }

    p {
      font-weight: 600;
      color: #262628;
    }
  }
`;

const ChatRoom: React.FC<{
  chatRoom: ChatRoomModel;
  loggedTeeWeeUser: TeeWeeUserModel;
}> = ({ chatRoom, loggedTeeWeeUser }) => {
  const dispatch = useDispatch();

  // Get other member
  const otherUser = useUserFromUid(
    chatRoom.members.filter(id => id !== loggedTeeWeeUser.uid)[0]
  );

  const memberInfo = useOneRoomMemberInfos(
    loggedTeeWeeUser.uid,
    chatRoom.roomId
  );

  if (!otherUser) {
    return null;
  }

  // If there is no message, there is no notification, if not seen there is if both, check dates
  const isActive = !(chatRoom.lastMessage && chatRoom.lastMessage.sentAt)
    ? false
    : !(memberInfo && memberInfo.lastReadAt)
    ? true
    : memberInfo.lastReadAt.seconds < chatRoom.lastMessage.sentAt.seconds;

  const content = (
    <>
      <div style={{ position: 'relative' }}>
        <ChatRoomProfile
          src={otherUser.photoURL || DEFAULT_PROFILE_PICTURE_URL}
          alt={'Profil ' + otherUser.username}
        />
        {isActive ? <NotificationIndicator /> : null}
      </div>
      <ChatRoomContent>
        <h1>{otherUser.username || 'Anonyme'}</h1>
        <p>
          {chatRoom.lastMessage ? (
            chatRoom.lastMessage.content
          ) : (
            <i>Pas de messages</i>
          )}
        </p>
      </ChatRoomContent>
    </>
  );

  return isActive ? (
    <ChatRoomContainerActive
      onClick={() => dispatch(push(ROUTES.CHATS + chatRoom.roomId))}
    >
      {content}
    </ChatRoomContainerActive>
  ) : (
    <ChatRoomContainer
      onClick={() => dispatch(push(ROUTES.CHATS + chatRoom.roomId))}
    >
      {content}
    </ChatRoomContainer>
  );
};

const MainChatsPage: React.FC = () => {
  const dispatch = useDispatch();
  const loggedTeeWeeUser = useCurrentUser();
  let chatsRooms = useChatsRooms(
    loggedTeeWeeUser ? loggedTeeWeeUser.chatsRooms : []
  );

  if (!loggedTeeWeeUser) {
    return null;
  }

  if (!chatsRooms) {
    chatsRooms = {};
  }

  const sortedChatsRooms = Object.values(chatsRooms).sort((roomA, roomB) => {
    if (!roomA.lastMessage || !roomA.lastMessage.sentAt) {
      return 1;
    } else if (!roomB.lastMessage || !roomB.lastMessage.sentAt) {
      return -1;
    }

    return roomB.lastMessage.sentAt.seconds - roomA.lastMessage.sentAt.seconds;
  });

  return (
    <FlexStructure>
      <HeaderBar
        showBackButton={false}
        button={{
          src: PictoGear,
          onClick: () => dispatch(push(ROUTES.SETTINGS_MAIN)),
        }}
      />
      <PageContainer>
        <MarginBody>
          {sortedChatsRooms.map(chatRoom => (
            <ChatRoom
              key={chatRoom.roomId}
              loggedTeeWeeUser={loggedTeeWeeUser}
              chatRoom={chatRoom}
            />
          ))}
        </MarginBody>
      </PageContainer>
      <BottomTabBar currentUser={loggedTeeWeeUser} />
    </FlexStructure>
  );
};

export default MainChatsPage;
