import React from 'react';
import styled from 'styled-components';

import BackButton from '../buttons/BackButton';

export const Title = styled.h1`
  font-size: 1.5em;
  color: #0056d1;
  font-weight: 800;
  font-family: HKNova;
  margin-bottom: 0.4em;
  margin-top: 1em;
  padding-top: 1.8em;
`;

export const Subtitle = styled.h2`
  font-size: 1em;
  color: #a6a6a6;
  font-family: HKNova;
  margin-bottom: 3em;
`;

const StyledContainer = styled.div`
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  background-color: #ffffff;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  justify-content: space-between;
  padding-left: 1.8em;
  padding-right: 1.8em;
`;

const Spacer = styled.div`
  height: 20vh;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledErrors = styled.div`
  color: red;
  margin-bottom: 1.3em;
  flex: none;
`;

const StyledInfos = styled.div`
  color: green;
  margin-bottom: 1.3em;
  flex: none;
`;

interface Error {
  message?: string;
}

export const Errors: React.FC<{ errors: (Error | undefined)[] }> = ({
  errors,
}) => {
  const errorsWithMessage = errors.filter(
    error => error?.message !== undefined
  );
  return (
    <StyledErrors>
      {errorsWithMessage?.map((error, index) => {
        const message = error?.message;
        return message && <p key={index}>{message}</p>;
      })}
    </StyledErrors>
  );
};

export const Infos: React.FC<{ infos: string[] }> = ({ infos }) => {
  return (
    <StyledInfos>
      {infos?.map((info, index) => {
        return <p key={index}>{info}</p>;
      })}
    </StyledInfos>
  );
};

export const Content = styled.div``;

export const Actions = styled.div`
  padding-bottom: 1.8em;
  text-align: center;

  & > i {
    font-style: italic;
    margin-bottom: 0.5rem;
    display: block;
    color: #a6a6a6;
  }
`;

interface FormProps {
  onSubmit?: () => void;
}

export const Container: React.FC<FormProps> = ({ onSubmit, children }) => {
  return (
    <StyledForm onSubmit={onSubmit}>
      <BackButton />
      <Spacer />
      <StyledContainer>{children}</StyledContainer>
    </StyledForm>
  );
};
