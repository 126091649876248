import React, { MutableRefObject } from 'react';
import styled from 'styled-components';
import { PictoScan } from '../../../assets/images/imagesSVG';

//////
const CenterToCurrentButtonStyle = styled.div`
  position: absolute;
  bottom: 7rem;
  left: 90%;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #ffffffcc;
  transform: translateX(-50%);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.5rem;
    margin: auto;
    width: 75%;
    height: auto;
    box-sizing: border-box;
  }
`;

export const centerToCurrent = (
  mapContainerRef: MutableRefObject<HTMLDivElement | null>,
  currentUserRef: MutableRefObject<HTMLDivElement | null>
) => {
  if (!currentUserRef.current || !mapContainerRef.current) {
    return;
  }
  const scroll: ScrollToOptions = {
    top:
      currentUserRef.current.offsetTop -
      mapContainerRef.current.clientHeight / 2,
    left:
      currentUserRef.current.offsetLeft -
      mapContainerRef.current.clientWidth / 2,
    behavior: 'smooth',
  };

  mapContainerRef.current.scrollTo(scroll);
};

export const MapCenterToCurrent: React.FC<{ centerFunction: () => void }> = ({
  centerFunction,
}) => {
  return (
    <CenterToCurrentButtonStyle onClick={centerFunction}>
      <img src={PictoScan} alt="Centrer sur moi" />
    </CenterToCurrentButtonStyle>
  );
};
