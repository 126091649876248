import React, { useState } from 'react';
import styled from 'styled-components';
import { ROUTES } from '../../../constants/routes';
import {
  MAP_TEXT_COLOR,
  DEFAULT_PROFILE_PICTURE_URL,
} from '../../../constants/style';
import { useUserFromUid } from '../../../controllers/UserHooks';
import { MAP_SIZE_OTHER_PROFILE, MAP_UNIT } from './MapConstants';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export const MapProfilePicture = styled.img``;

export const NameBubble = styled.p`
  display: block;
  position: absolute;
  top: 105%;
  left: 50%;
  min-width: 200%;
  transform: translateX(-50%);
  text-align: center;
  background-color: white;
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.2);

  color: ${MAP_TEXT_COLOR};
  padding: 0.8rem;
  border-radius: 0.8rem;
  z-index: 2;
  cursor: pointer;
`;

export const MapProfileStyle = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  width: ${MAP_SIZE_OTHER_PROFILE + MAP_UNIT};
  user-select: none;

  ${MapProfilePicture} {
    display: block;
    position: relative;
    border-radius: 50%;
    border: solid 5.5px #ffffff;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;

    width: 100%;
    height: auto;
    z-index: -1;
    cursor: pointer;
  }
`;

const MapAnonymousProfileStyle = styled(MapProfileStyle)`
  img {
    filter: grayscale(1);
  }
`;

//////
export const MapProfile: React.FC<{
  uid: string;
  left: string;
  top: string;
}> = ({ uid, left, top }) => {
  const dispatch = useDispatch();
  const user = useUserFromUid(uid);
  const [showActions, setShowActions] = useState<boolean>(false);

  if (!user) {
    return <MapAnonymousProfile left={left} top={top} />;
  }

  return (
    <MapProfileStyle
      style={{ left: left, top: top, zIndex: showActions ? 1 : 'auto' }}
    >
      <MapProfilePicture
        src={user.photoURL ? user.photoURL : DEFAULT_PROFILE_PICTURE_URL}
        alt={"Profil de l'utilisateur " + user.username}
        onClick={() => {
          if (!showActions) {
            setShowActions(true);
          } else {
            dispatch(push(ROUTES.USERS + uid));
          }
        }}
      />
      {showActions ? (
        <NameBubble onClick={() => setShowActions(false)}>
          {user.username ? user.username : <i>Anonyme</i>}
        </NameBubble>
      ) : null}
    </MapProfileStyle>
  );
};

export const MapAnonymousProfile: React.FC<{
  left: string;
  top: string;
}> = ({ left, top }) => {
  return (
    <MapAnonymousProfileStyle style={{ left: left, top: top }}>
      <MapProfilePicture
        src={DEFAULT_PROFILE_PICTURE_URL}
        alt={'Utilisateur grisé'}
      />
    </MapAnonymousProfileStyle>
  );
};
