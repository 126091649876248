import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ROUTES } from '../../../constants/routes';
import {
  MiniPictoShirt,
  PictoChat,
  PictoMap,
} from '../../../assets/images/imagesSVG';
import { MiniPictoUser } from '../../../assets/images/imagesSVG';
import { MAX_WIDTH } from '../../../constants/style';
import NotificationIndicator from '../misc/NotificationIndicator';
import { useHasChatsNotifications } from '../../../controllers/chats/ChatsHooks';
import { TeeWeeUserModel } from '../../../models/TeeWeeUserModel';
import { isBrowser } from 'react-device-detect';

const colorFilter =
  'brightness(0) saturate(100%) invert(18%) sepia(42%) saturate(6967%) hue-rotate(209deg) brightness(98%) contrast(101%)';

const currentColorFilter =
  'brightness(0) saturate(100%) invert(25%) sepia(89%) saturate(2778%) hue-rotate(355deg) brightness(109%) contrast(96%);';

const getImgCss = (color: string) => css`
  height: 1.5rem;
  filter: ${color};
  &:hover {
    cursor: pointer;
    filter: ${color} brightness(75%);
  }

  &:active {
    cursor: pointer;
    filter: ${color} brightness(50%);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const BottomTabBarMargin = styled.div`
    width: 100%;
    height: 8rem;
    position: relative;
  }
`;

const BottomTabBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: ${MAX_WIDTH};
  z-index: 10;
`;

const BottomTabBarStyle = styled.div`
  background-color: #ffffffdd;

  height: 5.5rem;
  overflow: hidden;
  border-radius: 32px 32px 0px 0px;
  padding: 34px 40px 0px 40px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;

  & > * {
    margin: auto;
    margin-top: 0;
  }
`;

const TabBarPicto = styled.img`
  ${getImgCss(colorFilter)};
`;

const TabBarPictoCurrent = styled.img`
  ${getImgCss(currentColorFilter)};
`;

const TabBarButtonContainer = styled.div`
  position: relative;
`;

const TabBarNotificationIndicator = styled(NotificationIndicator)`
  height: 40%;
  width: 40%;
  top: 2%;
  right: 5%;

  z-index: 5;
`;

export const TabBarButton: React.FC<{
  src: string;
  alt: string;
  route: string;
  hasNotifications?: boolean;
  forcedStatus?: boolean;
}> = ({ src, alt, route, forcedStatus, hasNotifications }) => {
  const location = useLocation();

  return (
    <Link to={route}>
      <TabBarButtonContainer>
        {hasNotifications ? <TabBarNotificationIndicator /> : null}
        {forcedStatus ||
        (forcedStatus === undefined && location.pathname === route) ? (
          <TabBarPictoCurrent src={src} alt={alt} />
        ) : (
          <TabBarPicto src={src} alt={alt} />
        )}
      </TabBarButtonContainer>
    </Link>
  );
};

export const BottomTabBar: React.FC<{ currentUser: TeeWeeUserModel }> = ({
  currentUser,
  children,
}) => {
  const hasNotificationMessages = useHasChatsNotifications(currentUser);
  const currentUserRoute = ROUTES.USERS + currentUser.uid;

  return (
    <BottomTabBarMargin>
      &nbsp;
      <BottomTabBarContainer
        style={isBrowser ? { left: '50%', transform: 'translateX(-50%)' } : {}}
      >
        {children}
        <BottomTabBarStyle>
          <TabBarButton src={PictoMap} alt="Fil" route={ROUTES.USERS} />
          <TabBarButton
            src={MiniPictoUser}
            alt="Ma page"
            route={currentUserRoute}
          />
          {currentUser &&
          currentUser.chatsRooms &&
          currentUser.chatsRooms.length > 0 ? (
            <TabBarButton
              src={PictoChat}
              alt="Messages"
              hasNotifications={hasNotificationMessages}
              route={ROUTES.CHATS}
            />
          ) : null}
          {currentUser &&
          currentUser.shirts &&
          currentUser.shirts.length === 0 ? (
            <Link to={ROUTES.SETTINGS_VESTIAIRE}>
              <TabBarPicto src={MiniPictoShirt} alt="Vestiaire" />
            </Link>
          ) : null}
        </BottomTabBarStyle>
      </BottomTabBarContainer>
    </BottomTabBarMargin>
  );
};
