import React from 'react';
import {
  MAP_UNIT,
  MAP_SIZE_OTHER_PROFILE,
  MAP_SPACE_BETWEEN_CIRCLES,
} from './MapConstants';
import { MapAnonymousProfile, MapProfile } from './MapProfile';

export const getMaxUserForRadius = (radius: number) => {
  const circonference = 2 * Math.PI * radius;
  const elementSize = MAP_SIZE_OTHER_PROFILE + MAP_SPACE_BETWEEN_CIRCLES;

  return Math.floor(circonference / elementSize);
};

export interface CircleInfo {
  radius: number;
  users: string[];
  startingAngle: number;
}

//////
export const UserCircle: React.FC<{
  info: CircleInfo;
  origin: number;
}> = ({ info, origin }) => {
  const betweenElements = (2 * Math.PI) / info.users.length;
  const profiles = [];

  // Calculate each position
  for (let i = 0; i < info.users.length; i++) {
    const angle = info.startingAngle + i * betweenElements;
    const y = Math.sin(angle);
    const x = Math.cos(angle);
    const top = origin + info.radius * y;
    const left = origin + info.radius * x;

    const user = info.users[i];
    profiles.push(
      user.startsWith('anonymous') ? (
        <MapAnonymousProfile
          key={user}
          left={left + MAP_UNIT}
          top={top + MAP_UNIT}
        />
      ) : (
        <MapProfile
          key={user}
          uid={user}
          left={left + MAP_UNIT}
          top={top + MAP_UNIT}
        />
      )
    );
  }

  return <ul>{profiles}</ul>;
};
