import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';

import ActionButton from '../buttons/ActionButton';
import LabelledInput from '../input/LabelledInput';
import * as Forms from './Form';
import { CatchFirebaseFormError } from '../../../constants/errorMessages';

interface FormData {
  email: string;
}

const ResetForm: React.FC = () => {
  const firebase = useFirebase();
  const { handleSubmit, register, errors, clearError, setError } = useForm<
    FormData
  >();
  const dispatch = useDispatch();

  const onSubmit = ({ email }: FormData) => {
    clearError();
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch(push('/'));
      })
      .catch(CatchFirebaseFormError(setError));
  };

  return (
    <Forms.Container onSubmit={handleSubmit(onSubmit)}>
      <Forms.Content>
        <Forms.Title>{`Mot de passe oublié ?`}</Forms.Title>
        <Forms.Subtitle>{`Renseigne ton email, si celle ci existe tu recevras un lien pour changer ton mot de passe.`}</Forms.Subtitle>
        <Forms.Errors errors={Object.values(errors)} />
        <LabelledInput
          innerRef={register}
          label="Email"
          name="email"
          type="email"
          required
        />
        <div style={{ height: '6em' }} />
      </Forms.Content>
      <Forms.Actions>
        <ActionButton text="Reinitialiser" variant="danger" type="submit" />
      </Forms.Actions>
    </Forms.Container>
  );
};

export default ResetForm;
