import React from 'react';
import { isBrowser } from 'react-device-detect';

import OnboardingSlider from '../../components/slider/OnboardingSlider';
import { BaseStructure } from '../../components/structure/DefaultBackground';
import { HeaderBar } from '../../components/structure/HeaderBar';
import DesktopWelcomePage from './DesktopWelcomePage';

const WelcomePage: React.FC = () => {
  return isBrowser ? (
    <DesktopWelcomePage />
  ) : (
    <BaseStructure style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <HeaderBar />
      <OnboardingSlider />
    </BaseStructure>
  );
};

export default WelcomePage;
