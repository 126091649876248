import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES } from '../../../constants/routes';
import { CARDS_TEXT_COLOR, TITLE_TEXT_COLOR } from '../../../constants/style';
import { TeeWeeUserModel } from '../../../models/TeeWeeUserModel';
import ActionButton from '../buttons/ActionButton';
import { BottomModal, BottomModalProps } from './BottomModal';

const LoginOnCommentButtonsStyle = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 2em;
`;
const LoginOnCommentStyle = styled.div`
  text-align: center;

  h1 {
    color: ${TITLE_TEXT_COLOR};
    font-size: 150%;
    margin-bottom: 1em;
  }

  p,
  i {
    color: ${CARDS_TEXT_COLOR};
    font-size: 0.95rem;
    padding-bottom: 1em;
  }

  i {
    font-size: 75%;
    font-style: italic;
  }
`;

interface LoginOnAnonymousCommentModalProps extends BottomModalProps {
  targetUser: TeeWeeUserModel;
  commentContent: string;
  sendAnonymousComment: () => void;
}

export const LoginOnAnonymousCommentModal: React.FC<LoginOnAnonymousCommentModalProps> = props => {
  const dispatch = useDispatch();
  return (
    <BottomModal
      visible={props.visible}
      disappearFunction={props.disappearFunction}
    >
      <LoginOnCommentStyle>
        <h1>Tu n'es pas connecté</h1>
        {!props.targetUser.allowAnonymousComments ? (
          <p>
            {props.targetUser.username} n'autorise pas les commentaires anonymes
          </p>
        ) : null}
        <p>
          Avec un compte {props.targetUser.username} pourrait échanger avec toi
        </p>
        <LoginOnCommentButtonsStyle>
          <ActionButton
            variant="primary"
            text="S'inscrire"
            onClick={() => dispatch(push(ROUTES.REGISTER))}
          />
          {props.targetUser.allowAnonymousComments ? (
            <ActionButton
              variant="secondary"
              text="Envoyer en anonyme"
              onClick={props.sendAnonymousComment}
            />
          ) : null}
          <ActionButton
            variant="secondary"
            text="Se connecter"
            onClick={() => dispatch(push(ROUTES.LOGIN))}
          />
          <i>
            En envoyant ce commentaire tu t&apos;entages à respecter les{' '}
            <Link to={ROUTES.LEGAL}>conditions d&apos;utilisation</Link> du site
          </i>
        </LoginOnCommentButtonsStyle>
      </LoginOnCommentStyle>
    </BottomModal>
  );
};
