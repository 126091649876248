import React from 'react';
import {
  BaseStructure,
  FlexStructure,
} from '../../components/structure/DefaultBackground';
import { HeaderBar } from '../../components/structure/HeaderBar';
import { useCart } from '../../../controllers/StoreHooks';
import ActionButton from '../../components/buttons/ActionButton';
import { H_MARGIN } from '../../../constants/style';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../../../constants/routes';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { isBrowser } from 'react-device-detect';
import { DefaultPic } from './components/DefaultPic';
import { useHistory } from 'react-router-dom';
import { Price, ProductName } from './components/StoreStyles';

const useFullWidthDisplay = isBrowser && window.innerWidth > 700;

const MainContainer = useFullWidthDisplay
  ? styled(FlexStructure)`
      background-color: #f5f5f5;
      width: 100%;
      max-width: 100%;
    `
  : styled(BaseStructure)``;

const PageContainer = useFullWidthDisplay
  ? styled.div`
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      flex-direction: row;
    `
  : styled.div``;

const SliderBox = useFullWidthDisplay
  ? styled.div`
      flex: none;
      width: 25%;
      margin: 5rem;
      align-self: center;
      background-color: #e7e7e7;
      box-shadow: 0 4px 66px 0 rgba(0, 0, 0, 0.15);
    `
  : ((({ children }) => <>{children}</>) as React.FC);

const ProductBox = useFullWidthDisplay
  ? styled.div`
      width: 25%;
      margin: 5rem;
      align-self: center;
      box-shadow: 0 4px 66px 0 rgba(0, 0, 0, 0.15);
      width: 50%;
      padding 1rem;
      border-radius:1rem;
      background-color: white;
    `
  : styled.div`
      padding: 1rem 1rem 0rem 1rem;
      background-color: white;
    `;

export const TextBlock = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: justify;
  color: #afafaf;
`;

export const ContactParagraph = styled.p`
  color: #3f3f3f;
  margin-top: 0.6rem;
  text-align: left;
`;

const NoStorePage: React.FC = () => {
  const dispatch = useDispatch();
  const cart = useCart();
  const history = useHistory();

  function Mailto({
    email,
    subject = '',
    body = '',
    children,
  }: {
    email: string;
    subject: string;
    body?: string;
    children: React.ReactNode;
  }) {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  }

  return (
    <MainContainer>
      <HeaderBar showBackButton={true} forceBackButton={ROUTES.ROOT} />
      <PageContainer>
        <SliderBox>{DefaultPic}</SliderBox>
        <ProductBox>
          <ProductName>Désolé, pas de boutique !</ProductName>
          <Price>Mais pourquoi ?</Price>
          <TextBlock>
            <p>
              Merci de l'intérêt mais TeeWee ne dispose pas d'une boutique
              actuellement. Notre production est limitée et nous réalisons de
              rares lots de tee-shirts.
              <br />
              Notre souhait est de fournir des vêtements avec une bonne qualité
              textile, sociale et environnementale et nous n'avons pas trouvé de
              partenaire satisfaisant à nos critères et capable de fournir des
              pièces uniques à la demande.
            </p>
            <ContactParagraph>
              Tu peux cependant nous contacter pour être mis au courant
              d'éventuelles disponibilités à l'adresse suivante :
              <Mailto
                email="contact@teewee.fr"
                subject="[TeeWee] Question sur les modèles"
                body=""
              >
                contact@teewee.fr
              </Mailto>
            </ContactParagraph>
          </TextBlock>
          <div style={{ padding: '0 ' + H_MARGIN }}>
            <ActionButton
              text="Revenir au site"
              variant="primary"
              onClick={history.goBack}
            />
          </div>
        </ProductBox>
      </PageContainer>
    </MainContainer>
  );
};

export default NoStorePage;
