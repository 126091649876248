import {
  ExtendedStorageInstance,
  ExtendedFirestoreInstance,
} from 'react-redux-firebase';
import { sendNewStatus } from '../controllers/UserController';
import { uploadImageFromString } from './CropUtils';
import { compressImage } from './CompressUtils';
import { CARDS_IMAGE_HEIGHT, CARDS_IMAGE_WIDTH } from '../constants/style';
import { User } from 'firebase';

export const uploadImageStatus = async (
  currentUser: User | null,
  storage: ExtendedStorageInstance,
  firestore: ExtendedFirestoreInstance,
  fullImageUrl: string,
  croppedImageUrl: string,
  onSend: () => void
) => {
  const storageRef = storage.storage().ref();

  const picturePath =
    '/users/' + currentUser?.uid + '/status/' + Math.floor(Date.now());
  const fullImageRef = storageRef.child(picturePath);
  const croppedImageRef = storageRef.child(picturePath + '_crop');

  // Upload full image
  const resizedFullImageStr = await compressImage(fullImageUrl, 510 * 1024);
  uploadImageFromString(fullImageRef, resizedFullImageStr);

  // Upload cropped blob
  if (croppedImageUrl == null) {
    throw new TypeError('Cropped must not be null');
  }
  const resizedCroppedImageStr = await compressImage(
    croppedImageUrl,
    256 * 1024,
    CARDS_IMAGE_WIDTH,
    CARDS_IMAGE_HEIGHT
  );
  uploadImageFromString(croppedImageRef, resizedCroppedImageStr);

  // Upload status
  if (currentUser == null) {
    throw new TypeError('Current user must not be null');
  }
  sendNewStatus(currentUser, firestore, 'red', undefined, picturePath);

  onSend();
};
