import React from 'react';
import styled from 'styled-components';

const StyledBaseInput = styled.input`
  border: none;
  font-family: HKNova;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  margin-top: 0.5em;
  font-size: 1.2em;
  color: #37373b;
  opacity: 0.7;
  padding-bottom: 0.4em;
`;

export const StyledBaseTextArea = styled.textarea`
  border: none;
  font-family: HKNova;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  margin-top: 0.5em;
  font-size: 1.2em;
  color: #17171b;
  background-color: #f2f2f2;
  min-height: 5rem;
  opacity: 0.7;
  padding: 0.5rem;
`;

interface BaseInputProps {
  innerRef: (() => void) | React.RefObject<HTMLInputElement>;
}

export const BaseInput: React.FC<BaseInputProps> = props => (
  <StyledBaseInput ref={props.innerRef} {...props} />
);

export default BaseInput;
