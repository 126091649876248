import firebase from 'firebase';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExtendedFirestoreInstance,
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestore,
  useFirestoreConnect,
  useFirebase,
} from 'react-redux-firebase';
import { Redirect, RouteComponentProps, useLocation } from 'react-router-dom';
import { COLLECTIONS } from '../constants/collections';
import { ROUTES } from '../constants/routes';
import { ShirtModel } from '../models/ShirtModel';
import { addLoginRedirectionUrl } from '../redux/reducers/LoginRedirectionReducer';
import { State } from '../redux/reducer';
import { useCurrentUser } from '../utils/AuthUtils';
import NonExistingShirtErrorPage from '../views/pages/errors/NonExistingShirtErrorPage';
import { LoadingPage } from '../views/pages/misc/LoadingPage';
import ShirtLinkingPage from '../views/pages/shirt/ShirtLinkingPage';
import { addNewScanned, createFirstScanned } from './UserController';

export const linkShirt = (
  code: string,
  targetUid: string,
  firestore: ExtendedFirestoreInstance
) => {
  firestore
    .collection(COLLECTIONS.SHIRTS)
    .doc(code)
    .update({
      owner: targetUid,
      status: 'linked',
      linkedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const unlinkShirt = (
  code: string,
  firestore: ExtendedFirestoreInstance
) => {
  firestore
    .collection(COLLECTIONS.SHIRTS)
    .doc(code)
    .update({
      owner: firebase.firestore.FieldValue.delete(),
      status: 'produced',
      linkedAt: firebase.firestore.FieldValue.delete(),
    });
};

/**
 * This function returns :
 * - undefined when loading
 * - null if the shirt does not exis
 * - ShirtModel object with shirt infos if the shirt does exist
 * @param code shirt code
 */
export const useShirt = (code: string) => {
  const codeUppercase = code.toUpperCase();
  const storeDocument: ReduxFirestoreQuerySetting = {
    collection: COLLECTIONS.SHIRTS,
    doc: codeUppercase,
  };

  useFirestoreConnect([storeDocument]);
  const shirt = useSelector((state: State) => {
    const collection = state.firestore.data[COLLECTIONS.SHIRTS] as {
      [id: string]: ShirtModel;
    };
    return collection && (collection[codeUppercase] as ShirtModel);
  });

  if (!shirt && !isLoaded(shirt)) {
    return undefined;
  }

  if (!shirt && isLoaded(shirt)) {
    return null;
  }

  return shirt;
};

export const ShirtsScanController: React.FC<RouteComponentProps<{
  code: string;
}>> = ({ match }) => {
  const [displayLink, setDisplayLink] = useState<boolean>(false);
  const shirt = useShirt(match.params.code);
  const firebase = useFirebase();
  const loggedUser = firebase.auth().currentUser;
  const loggedUserTeewee = useCurrentUser();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const location = useLocation();

  if (shirt === undefined) {
    //Still loading
    return <LoadingPage />;
  }
  if (shirt === null) {
    // Shirt does not exist
    return <NonExistingShirtErrorPage />;
  }
  if (shirt.owner && !displayLink) {
    console.log('Le courant: ', loggedUserTeewee, loggedUser);
    // Shirt has an owner
    // Check if it is a new scanned
    if (!loggedUser) {
      console.log('T PA LOGGE');
      dispatch(addLoginRedirectionUrl(location.pathname));
    } else if (loggedUser && loggedUser.uid !== shirt.owner) {
      console.log('Salut poto');
      if (!loggedUserTeewee || !loggedUserTeewee.scanned) {
        console.log('Will create first scanned');
        createFirstScanned(shirt.owner, loggedUser.uid, firestore).catch(e =>
          console.error('Problème à la création', e)
        );
      } else if (!(shirt.owner in loggedUserTeewee.scanned)) {
        addNewScanned(shirt.owner, loggedUserTeewee.uid, firestore);
      }
    }
    return <Redirect to={ROUTES.USERS + shirt.owner} />;
  }

  if (!displayLink) {
    setDisplayLink(true);
  }

  // Claim the shirt
  return <ShirtLinkingPage shirt={shirt} />;
};
