// URL
const ADD_LOGIN_REDIRECTION_URL = '@@teewee/ADD_LOGIN_REDIRECTION_URL';
const REMOVE_LOGIN_REDIRECTION_URL = '@@teewee/REMOVE_LOGIN_REDIRECTION_URL';

interface URLAction {
  type: string;
  url?: string;
}

export function addLoginRedirectionUrl(url: string): URLAction {
  return {
    type: ADD_LOGIN_REDIRECTION_URL,
    url,
  };
}

export function removeLoginRedirectionUrl(): URLAction {
  return {
    type: REMOVE_LOGIN_REDIRECTION_URL,
  };
}

type ReducerType = string | null;

export function LoginRedirectionReducer(
  state = null,
  action: URLAction
): ReducerType {
  switch (action.type) {
    case ADD_LOGIN_REDIRECTION_URL:
      return action.url ? action.url : null;
    case REMOVE_LOGIN_REDIRECTION_URL:
      return null;
    default:
      return state;
  }
}
