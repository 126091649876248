import React, { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { PictoSend } from '../../../assets/images/imagesSVG';

const padding = '1rem';

const InputCardStyle = styled.textarea`
  background-color: white;
  color: #262628;
  border: solid 1px #c6c5c5;

  border-radius: 17px;
  padding: ${padding};
  min-height: 8rem;
  margin-bottom: 0.5rem;
  width: 100%;
  box-sizing: border-box;

  text-align: left;
  resize: none;
  font-family: HKNova;
  font-size: 90%;
  color: #a6a6a6;
`;

const SendButton = styled.button`
  text-decoration: none;
  border: none;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);

  position: absolute;
  bottom: ${padding};
  right: ${padding};
  opacity: 0.5;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 1;
  }
`;

const CustomForm = styled.form`
  position: relative;
`;

interface InputCardProps {
  placeholder: string;
  style?: CSSProperties;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>, value: string) => void;
}

// eslint-disable-next-line react/display-name
export const InputCard = React.forwardRef<HTMLTextAreaElement, InputCardProps>(
  ({ placeholder, style, onSubmit }, ref) => {
    const [value, setValue] = useState('');

    return (
      <CustomForm onSubmit={onSubmit && (e => onSubmit(e, value))} action="#">
        <InputCardStyle
          value={value}
          placeholder={placeholder}
          style={style}
          onChange={e => setValue(e.target.value)}
          ref={ref}
        />
        <SendButton type="submit">
          <img src={PictoSend} alt="Send picto" />
        </SendButton>
      </CustomForm>
    );
  }
);
