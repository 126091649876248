import React, { useState } from 'react';
import styled from 'styled-components';
import { StatusModel } from '../../../models/StatusModel';
import { PictoWrite } from '../../../assets/images/imagesSVG';
import { ButtonIcon } from '../buttons/ButtonCard';
import { StatusCardContent } from './StatusCard';
import { NewStatusCard, StatusButtonCard } from './NewStatusCard';

export const CurrentStatusStyle = styled.div`
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
  margin-bottom: 1rem;
`;

export const CurrentStatusCard: React.FC<{
  status: StatusModel;
  isMyPage: boolean;
}> = ({ status, isMyPage }) => {
  const [isPosting, setIsPosting] = useState(false);
  return (
    <CurrentStatusStyle>
      {isPosting ? (
        <NewStatusCard onClose={() => setIsPosting(false)} />
      ) : (
        <>
          <StatusCardContent status={status} />
          {isMyPage ? (
            <StatusButtonCard
              style={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
              }}
              onClick={() => setIsPosting(true)}
            >
              <ButtonIcon src={PictoWrite} />
            </StatusButtonCard>
          ) : null}
        </>
      )}
    </CurrentStatusStyle>
  );
};
