import React from 'react';
import {
  H_MARGIN_VALUE,
  H_MARGIN_UNIT,
  H_MARGIN,
  CARDS_TEXT_COLOR,
  CARDS_TITLE_COLOR,
} from '../../../constants/style';
import { BottomRounded } from '../../components/structure/BottomRounded';
import {
  MiniPictoArrowRight,
  PictoCamera,
} from '../../../assets/images/imagesSVG';
import styled from 'styled-components';

export const MenuItemStyle = styled.div`
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  direction: row;

  div {
    flex-grow: 100;
    padding-left: ${H_MARGIN};
    padding-right: ${H_MARGIN};
  }

  &:hover {
    color: ${CARDS_TEXT_COLOR};
    filter: brightness(75%);
  }
`;

export const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  color: ${CARDS_TITLE_COLOR};
`;

export const CustomBottom = styled(BottomRounded)`
  padding-left: ${2 * H_MARGIN_VALUE + H_MARGIN_UNIT};
  padding-right: ${2 * H_MARGIN_VALUE + H_MARGIN_UNIT};
  h1 {
    color: ${CARDS_TEXT_COLOR};
    margin-bottom: 2rem;
  }
`;

/* Profile Picture section */
export const ProfilePictureSection = styled.div`
  padding: 0;
  margin-right: 3rem;
  width: 20%;
  position: relative;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  border: solid 5.5px #ffffff;
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
`;

export const ProfilePictureButton = styled.button`
  border-radius: 50%;
  border: solid 5.5px #ffffff;
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.08);

  position: absolute;
  right: -1rem;
  bottom: -0.2rem;
  height: 2rem;
  width: 2rem;

  background: url(${PictoCamera}), #ffffff;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-position: center;
  padding: 0;
  cursor: pointer;

  label {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;

    input {
      display: none;
    }
  }
`;

export const ProfileInfos = styled.div`
  margin: ${2 * H_MARGIN_VALUE + H_MARGIN_UNIT};
  margin-bottom: ${H_MARGIN};
  padding: 0;
  width: 80%;

  display: flex;
  direction: row;
  align-items: center;

  text-align: left;

  p {
    font-size: 0.9rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${CARDS_TEXT_COLOR};
    margin-bottom: 0.5rem;
  }

  h1 {
    font-family: HKNova;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.73;
    letter-spacing: normal;
    color: #ffffff;
  }
`;

export const MenuItem: React.FC<{
  icon: string;
  text: string;
  onClick: () => void;
}> = ({ icon, text, onClick }) => {
  return (
    <MenuItemStyle onClick={onClick}>
      <img src={icon} alt={text} />
      <div>{text}</div>
      <img src={MiniPictoArrowRight} alt={'Flèche'} />
    </MenuItemStyle>
  );
};

export const SettingsSection: React.FC<{ title: string }> = ({
  title,
  children,
}) => {
  return (
    <CustomBottom>
      <h1>{title}</h1>
      {children}
    </CustomBottom>
  );
};
